import { Injectable } from "@angular/core";
import Compressor from "compressorjs";
import PptxGenJS from "pptxgenjs";
import { Observable, Observer, of, Subject } from "rxjs";
import { switchMap } from "rxjs/operators";
import { DateUtil } from "../../helpers/date.util";
import { PptFields } from "../../modals/ppt/ppt-fields-enum";
import { PptFormatEnum } from "../../modals/ppt/ppt-format-enum";
import { PPTLogoPositionEnum } from "../../modals/ppt/ppt-logo-position-enum";
import { PptGenerationData } from "../../modals/ppt/pptgenerationdata";
import { SlideLayoutEnum } from "../../modals/ppt/slide-layout-enum";
import { SIBConstants } from "../../shared/constants/SIBConstant";
import { CurrencyHelperPipe } from "../../shared/helpers/currency.pipe/currencyHelperPipe";
import { AppUrls } from "../urls";
import * as FileSaver from "file-saver";
import { SlideFormat } from "../../modals/ppt/slide-format-enum";
import { SlideSizeRatio } from "../../modals/ppt/slide-size-ratio-enum";
import { AuthService } from "../../login-service/auth.service";
@Injectable({
  providedIn: "root",
})
export class PptGeneratorService {
  pptx: any;
  imageName: string = "assets/layout/images/customer-image.png";

  operatedDateText: string;
  tabIndex: number;
  imageObject = {
    width: 0,
    height: 0,
    url: "https://firebasestorage.googleapis.com/v0/b/showitbig-411c5.appspot.com/o/dev%2FSIB0001%2Fhoardings%2FOOH-0135%2FOOH-0135%20-%20Ahmedabad%2C%20kalasagar1%20-%2020X20.jpg?alt=media&token=9318e1e5-c32e-4f5d-805b-49a9060bb201",
  };
  logoUrl: string;
  pptText: string;
  pptRedirectUrl: string;
  portalBaseUrl: string;
  layout: any;

  layoutWidth: number;
  layoutHeight: number;
  pptLogoBase64 = new PptGenerationData();
  pptLogoPosition: string;
  middleSitesFormat: string;
  slideFormat: string;
  pptFormat: PptFormatEnum;
  slideRatio: string;
  noImageUrl =
    "https://firebasestorage.googleapis.com/v0/b/showitbig-411c5.appspot.com/o/sib-assets%2Fno_image_available.png?alt=media&token=74c70498-65b2-4ced-920a-a068677f0f89";

  campaignPptDownloadComplete$: Subject<string> = new Subject();
  planPptDownloadComplete$: Subject<string> = new Subject();
  noImageBlobData: any;

  constructor(
    private currencyPipe: CurrencyHelperPipe,
    private auth: AuthService
  ) {
    let pptData = { data: "", height: 0, width: 0 };
    of([])
      .pipe(
        switchMap((element) => {
          return this.setImageDataForPPT(this.noImageUrl, pptData);
        })
      )
      .subscribe((result) => {
        this.noImageBlobData = result;
      });
  }

  instantiatePpt() {
    this.pptx = new PptxGenJS();
  }

  setSlideMaster(pptConfig?) {
    this.pptx.defineSlideMaster({
      title: "MASTER_SLIDE",
      bkgd: "FFFFFF",

      background: {
        path:
          pptConfig &&
          pptConfig.backGroundImage &&
          pptConfig.backGroundImage.url
            ? pptConfig.backGroundImage.url
            : "",
      },

      //   objects: [
      //     { 'line': { x: 0, y: 0, w: 0, line: '0088CC', lineSize: 12, h: '100%', align: 'left' } },
      //     { 'image': { x: 1, y: 1, w: '50%', h: '18%', sizing: { type: 'contain' }, path: this.logoUrl } },
      //     { 'line': { x: 1, y: 2.5, w: 8, h: 0, line: '000000', lineSize: 1 } },
      //     { 'text': { text: 'Test User', options: { x: 1.0, y: 2.7, w: 5.5, h: 0, fontSize: 14, margin: 5, bold: true } } },
      //     { 'text': { text: '1234567890', options: { x: 1.0, y: 3.2, w: 5.5, h: 0, fontSize: 14, margin: 5 } } },
      //     { 'text': { text: 'testuser@gmail.com', options: { x: 1, y: 3.7, w: 5.5, h: 0, fontSize: 14, margin: 5 } } },
      //     { 'text': { text: '08 Apr, 2019', options: { x: 7.8, y: 2.7, w: 5.5, h: 0, fontSize: 14, margin: 5, bold: true } } },
      //   ],
    });
  }

  itemSlideMaster(item) {
    this.pptx.defineSlideMaster({
      title: "ITEM_SLIDE",
      bkgd: "FFFFFF",
      objects: [
        {
          image: {
            x: 1,
            y: "5%",
            w: 8,
            h: this.checkWideScreenSlideRatio() ? 2.6 : 5.5,
            sizing: { type: "contain", w: 8, h: 2 },
            path: item.images[0].url,
          },
        },
      ],
    });
  }

  setLayout(pptConfig?) {
    this.slideRatio =
      pptConfig && pptConfig.slideRatio ? pptConfig.slideRatio : "";
    this.pptx.layout = this.checkWideScreenSlideRatio()
      ? "LAYOUT_16x9"
      : "LAYOUT_4x3";
    this.layoutWidth = this.pptx.LAYOUTS[this.pptx.layout].width;
    this.layoutHeight = this.pptx.LAYOUTS[this.pptx.layout].height;
  }

  setFirstPageLayout(data, format?) {
    this.pptFormat = format;
    const slide = this.pptx.addNewSlide();
    let xDistance;

    switch (format) {
      case "Full Detail":
      case "Full Detail(Compress)":
      case "Less Detail":
      case "Less Detail(Compress)":
      default: {
        slide.addShape(this.pptx.shapes.LINE, {
          x: 0,
          y: 0,
          w: 0,
          line: "0088CC",
          lineSize: 6,
          h: "100%",
          align: "left",
        });
        slide.addShape(this.pptx.shapes.LINE, {
          x: 1,
          y: 3,
          w: 8,
          h: 0,
          line: "000000",
          lineSize: 1,
        });
        slide.addImage({
          x: 1,
          y: 1,
          w: "50%",
          h: "20%",
          sizing: { type: "contain" },
          path: this.logoUrl,
        });

        slide.addText(data.employee.fullName, {
          x: 1.0,
          y: 3.2,
          w: 5.5,
          h: 0,
          fontSize: 14,
          margin: 5,
          bold: true,
        });
        slide.addText(data.employee.number, {
          x: 1.0,
          y: 3.7,
          w: 5.5,
          h: 0,
          fontSize: 14,
          margin: 5,
        });
        slide.addText(data.employee.emailId, {
          x: 1,
          y: 4.2,
          w: 5.5,
          h: 0,
          fontSize: 14,
          margin: 5,
        });
        slide.addText(DateUtil.formatDateDDMMMYY(new Date()), {
          x: 7.8,
          y: 3.2,
          w: 5.5,
          h: 0,
          fontSize: 14,
          margin: 5,
          bold: true,
        });
        if (this.pptText) {
          if (this.pptText.length <= 10) {
            xDistance = 8;
          } else if (this.pptText.length > 11 && this.pptText.length <= 20) {
            xDistance = 7.5;
          } else if (this.pptText.length > 20 && this.pptText.length <= 30) {
            xDistance = 6.5;
          } else if (this.pptText.length > 30 && this.pptText.length <= 40) {
            xDistance = 6;
          } else if (this.pptText.length > 40) {
            xDistance = 5;
          }
        }
        slide.addText(
          [
            {
              text: this.pptText,
              options: {
                hyperlink: {
                  url: this.pptRedirectUrl,
                  tooltip: "Visit Homepage",
                },
              },
            },
          ],
          {
            x: xDistance,
            y: 7,
            w: 5.5,
            h: 0,
            fontSize: 14,
            margin: 5,
            bold: true,
          }
        );
        break;
      }
      case "Customer Configured": {
        if (data.customer.pptConfigPresent) {
          if (
            data.customer.pptConfig.headerSlide &&
            data.customer.pptConfig.headerSlide.url
          ) {
            slide.background = {
              path: data.customer.pptConfig.headerSlide.url,
            };
          }
          if (
            data.customer.pptConfig.logo &&
            data.customer.pptConfig.logo.url
          ) {
            this.pptLogoImageConversionToBase64(data, slide);
          }
        }
        break;
      }
      case "Vendor Specific": {
        if (data.headerSlide && data.headerSlide.url) {
          slide.background = {
            path: data.headerSlide.url,
          };
        }
        if (data.logo && data.logo.url) {
          this.pptLogoImageConversionToBase64(data, slide);
        }
        break;
      }
    }
  }

  pptLogoImageConversionToBase64(data, slide) {
    const logoUrl =
      this.pptFormat === "Vendor Specific"
        ? data.logo.url
        : data.customer.pptConfig.logo.url;
    const pptGenerationData = new PptGenerationData();
    of(logoUrl)
      .pipe(
        switchMap((element) => {
          return this.getBase64ImageFromURL(logoUrl, pptGenerationData);
        }),
        switchMap((logoData) => {
          return this.dataURItoBLob(logoData["data"], logoData);
        }),
        switchMap((logoData) => {
          return this.blobToFile(logoData["data"], logoData);
        }),
        switchMap((logoData) => {
          return this.compressFile(logoData["data"], logoData);
        }),
        switchMap((logoData) => {
          return this.getBase64FromFile(logoData["data"], logoData);
        })
      )
      .subscribe((pptLogoBase64: PptGenerationData) => {
        const imageMeasurement = this.calculatePPTLogoPosition(
          pptLogoBase64,
          data
        );
        slide.addImage({
          x: imageMeasurement.imageX,
          y: imageMeasurement.imageY,
          w: imageMeasurement.imageWidth,
          h: imageMeasurement.imageHeight,
          sizing: {
            type: "contain",
            w: imageMeasurement.imageWidth,
            h: imageMeasurement.imageHeight,
          },
          path: logoUrl,
        });
      });
  }

  // set item slide for plan item
  setItemSlide(item, pptConfig) {
    // const slide = this.pptx.addNewSlide();
    const slide =
      this.pptFormat === "Customer Configured" ||
      this.pptFormat === "Vendor Specific"
        ? this.pptx.addSlide({ masterName: "MASTER_SLIDE" })
        : this.pptx.addNewSlide();

    let arrTabRows1;
    this.middleSitesFormat =
      pptConfig && pptConfig.middleSlideLayout
        ? SlideLayoutEnum[pptConfig.middleSlideLayout]
        : "";
    this.slideFormat =
      pptConfig && pptConfig.middleSlideFormat
        ? SlideFormat[pptConfig.middleSlideFormat]
        : "";
    this.slideRatio =
      pptConfig && pptConfig.slideRatio ? pptConfig.slideRatio : "";

    switch (this.pptFormat) {
      case "Full Detail": {
        arrTabRows1 = [
          [
            {
              text: item.header,
              options: {
                colspan: 2,
                fill: "E4E9EB",
                margin: [0, 0, 0, 10],
                bold: true,
                align: "center",
              },
            },
          ],
          [
            {
              text: "Size : " + (item.size ? item.size : "-"),
              options: { margin: [0, 0, 0, 10] },
            },
            {
              text: "Light : " + item.lightType,
              options: { margin: [0, 0, 0, 10] },
            },
          ],
          [
            {
              text:
                "Rate for (" +
                item.numberOfDays +
                " days) : " +
                (item.rate
                  ? this.currencyPipe.transform(Math.round(item.rate)) + "/-"
                  : " - "),
              options: { margin: [0, 0, 0, 10] },
            },
            {
              text: "Availability : " + item.availabilityDate,
              options: { margin: [0, 0, 0, 10] },
            },
          ],
        ];
        break;
      }
      case "Full Detail(Compress)": {
        arrTabRows1 = [
          [
            {
              text: item.header,
              options: {
                colspan: 2,
                fill: "E4E9EB",
                margin: [0, 0, 0, 10],
                bold: true,
                align: "center",
              },
            },
          ],
          [
            {
              text: "Size : " + (item.size ? item.size : "-"),
              options: { margin: [0, 0, 0, 10] },
            },
            {
              text: "Light : " + item.lightType,
              options: { margin: [0, 0, 0, 10] },
            },
          ],
          [
            {
              text:
                "Rate for (" +
                item.numberOfDays +
                " days) : " +
                (item.rate
                  ? this.currencyPipe.transform(Math.round(item.rate)) + "/-"
                  : " - "),
              options: { margin: [0, 0, 0, 10] },
            },
            {
              text: "Availability : " + item.availabilityDate,
              options: { margin: [0, 0, 0, 10] },
            },
          ],
        ];
        break;
      }
      case "Less Detail": {
        arrTabRows1 = [
          [
            {
              text: item.header,
              options: {
                colspan: 2,
                fill: "E4E9EB",
                margin: [0, 0, 0, 10],
                bold: true,
                align: "center",
              },
            },
          ],
        ];
        break;
      }
      case "Less Detail(Compress)": {
        arrTabRows1 = [
          [
            {
              text: item.header,
              options: {
                colspan: 2,
                fill: "E4E9EB",
                margin: [0, 0, 0, 10],
                bold: true,
                align: "center",
              },
            },
          ],
        ];
        break;
      }
      case "Customer Configured":
      case "Vendor Specific": {
        // if (pptConfig.backGroundImage && pptConfig.backGroundImage.url) {
        //   slide.background = {
        //     path: pptConfig.backGroundImage.url
        //   };
        // }
        arrTabRows1 = [
          [
            {
              text: item.header,
              options: {
                colspan:
                  this.middleSitesFormat === "Right Aligned Description"
                    ? 1
                    : 2,
                fill: "E4E9EB",
                margin: [0, 0, 0, 10],
                bold: true,
                align: "center",
              },
            },
          ],
        ];
        if (
          pptConfig.middleSlideFormat &&
          pptConfig.middleSlideFormat === "LESS_DETAILS"
        ) {
        } else {
          this.setFieldsForInventoryDescription(arrTabRows1, pptConfig, item);
        }
        break;
      }
    }
    if (
      this.pptFormat === "Customer Configured" ||
      this.pptFormat === "Vendor Specific"
    ) {
      this.setFormatForMiddleSlides(
        slide,
        item,
        arrTabRows1,
        pptConfig.middleSlideBorder,
        pptConfig
      );
    } else {
      const imageMeasurement = this.calculateImageHeightAndWidth(item);
      slide.addImage({
        x: imageMeasurement.imageX,
        y: "4%",
        w: imageMeasurement.imageWidth,
        h: imageMeasurement.imageHeight,
        sizing: {
          type: "contain",
          w: imageMeasurement.imageWidth,
          h: imageMeasurement.imageHeight,
        },
        path: item.data,
      });
      const tabOpts1 = {
        x: 0.5,
        y: this.checkWideScreenSlideRatio()
          ? (this.pptFormat === "Less Detail" || this.pptFormat=="Less Detail(Compress)")
            ? 5
            : 4.4
          : (this.pptFormat === "Less Detail" || this.pptFormat=="Less Detail(Compress)")
          ? 6.8
          : 6,
        w: "90%",
        h: this.checkWideScreenSlideRatio()
          ? (this.pptFormat === "Less Detail" || this.pptFormat=="Less Detail(Compress)")
            ? 0.3
            : 1
          : (this.pptFormat === "Less Detail" || this.pptFormat=="Less Detail(Compress)")
          ? 0.41
          : 1.25,
        fill: "FFFFFF",
        color: "3D3D3D",
        fontSize: this.checkWideScreenSlideRatio() ? 12 : 14,
        align: "l",
        valign: "m",
        autoPage: false,
        border: { pt: 0 },
      };
      slide.addTable(arrTabRows1, tabOpts1);
    }
  }

  setFieldsForInventoryDescription(arrTabRows1, pptConfig, item) {
    for (let i = 0; i < pptConfig.middleSlideFields.length; i++) {
      const field =
        pptConfig.middleSlideFields[i] === "RATE"
          ? "Rate for (" + item.numberOfDays + " days) : "
          : pptConfig.middleSlideFields[i] === "MONTHLY_RATE"
          ? this.setMonthlyRateLabel() + " : "
          : PptFields[pptConfig.middleSlideFields[i]] + " : ";
      let fieldValue;

      switch (pptConfig.middleSlideFields[i]) {
        case "SIZE": {
          fieldValue = item.size ? item.size : "-";
          break;
        }
        case "LIGHT": {
          fieldValue = item.lightType;
          break;
        }
        case "RATE": {
          fieldValue = item.rate
            ? this.currencyPipe.transform(Math.round(item.rate)) + "/-"
            : " - ";
          break;
        }
        case "MONTHLY_RATE": {
          fieldValue = item.monthlyRate
            ? this.currencyPipe.transform(Math.round(item.monthlyRate)) + "/-"
            : " - ";
          break;
        }
        case "AVAILABILITY": {
          fieldValue = item.availabilityDate;
          break;
        }
      }
      if (this.middleSitesFormat !== "Right Aligned Description") {
        if (
          arrTabRows1.length === 1 ||
          (arrTabRows1[1] && arrTabRows1[1].length === 1)
        ) {
          arrTabRows1.length === 1 && !arrTabRows1[1]
            ? arrTabRows1.push([])
            : "";
          arrTabRows1[1].push({
            text: field + fieldValue,
            options: {
              colspan: pptConfig.middleSlideFields.length === 1 ? 2 : 1,
              margin: [0, 0, 0, 10],
            },
          });
        } else {
          arrTabRows1.length === 2 && !arrTabRows1[2]
            ? arrTabRows1.push([])
            : "";
          arrTabRows1[2].push({
            text: field + fieldValue,
            options: {
              colspan: pptConfig.middleSlideFields.length === 3 ? 2 : 1,
              margin: [0, 0, 0, 10],
            },
          });
        }
      } else {
        arrTabRows1[i + 1] = [];
        arrTabRows1[i + 1].push({
          text: field + fieldValue,
          options: { margin: [5, 0, 5, 10] },
        });
      }
    }

    if (this.middleSitesFormat !== "Right Aligned Description") {
      if (pptConfig.middleSlideFields.length === 1) {
        arrTabRows1[1].push({
          text: "",
          options: { margin: [0, 0, 0, 10] },
        });
      } else if (pptConfig.middleSlideFields.length === 3) {
        arrTabRows1[2].push({
          text: "",
          options: { margin: [0, 0, 0, 10] },
        });
      }
    }
  }

  setFormatForMiddleSlides(
    slide,
    item,
    arrTabRows1,
    middleSlideBorder,
    pptConfig
  ) {
    const middleSlideFields = pptConfig.middleSlideFields.length;
    switch (this.middleSitesFormat) {
      case "Bottom Aligned Description": {
        const imageMeasurement = this.calculateImageHeightAndWidth(
          item,
          null,
          pptConfig
        );
        slide.addImage({
          x: imageMeasurement.imageX,
          y: "4%",
          w: imageMeasurement.imageWidth,
          h: imageMeasurement.imageHeight,
          sizing: {
            type: "contain",
            w: imageMeasurement.imageWidth,
            h: imageMeasurement.imageHeight,
          },
          path: item.data,
        });
        const tabOpts1 = {
          x: 0.5,
          y: this.checkWideScreenSlideRatio()
            ? pptConfig.middleSlideFormat === "LESS_DETAILS"
              ? 5
              : 4.4
            : pptConfig.middleSlideFormat === "LESS_DETAILS"
            ? 6.8
            : 6,
          w: "90%",
          h: this.checkWideScreenSlideRatio()
            ? pptConfig.middleSlideFormat === "LESS_DETAILS"
              ? 0.3
              : middleSlideFields > 2
              ? 1
              : 0.75
            : pptConfig.middleSlideFormat === "LESS_DETAILS"
            ? 0.41
            : middleSlideFields > 2
            ? 1.25
            : 1,
          fill: "FFFFFF",
          color: "3D3D3D",
          fontSize: this.checkWideScreenSlideRatio() ? 12 : 14,
          align: "l",
          valign: "m",
          autoPage: false,
          border: {
            pt: middleSlideBorder ? 1 : 0,
            type: middleSlideBorder ? "solid" : "none",
          },
        };
        slide.addTable(arrTabRows1, tabOpts1);
        break;
      }
      case "Top Aligned Description": {
        const imageMeasurement = this.calculateImageHeightAndWidth(
          item,
          null,
          pptConfig
        );
        const tabOpts1 = {
          x: 0.5,
          y: "4%",
          w: "90%",
          h: this.checkWideScreenSlideRatio()
            ? pptConfig.middleSlideFormat === "LESS_DETAILS"
              ? 0.3
              : middleSlideFields > 2
              ? 1
              : 0.75
            : pptConfig.middleSlideFormat === "LESS_DETAILS"
            ? 0.41
            : middleSlideFields > 2
            ? 1.25
            : 1,
          fill: "FFFFFF",
          color: "3D3D3D",
          fontSize: this.checkWideScreenSlideRatio() ? 12 : 14,
          align: "l",
          valign: "m",
          autoPage: false,
          border: {
            pt: middleSlideBorder ? 1 : 0,
            type: middleSlideBorder ? "solid" : "none",
          },
        };
        slide.addTable(arrTabRows1, tabOpts1);
        slide.addImage({
          x: imageMeasurement.imageX,
          y: this.checkWideScreenSlideRatio()
            ? pptConfig.middleSlideFormat === "LESS_DETAILS"
              ? 0.75
              : middleSlideFields > 2
              ? 1.4
              : 1.2
            : pptConfig.middleSlideFormat === "LESS_DETAILS"
            ? 1
            : 2,
          w: imageMeasurement.imageWidth,
          h: imageMeasurement.imageHeight,
          sizing: {
            type: "contain",
            w: imageMeasurement.imageWidth,
            h: imageMeasurement.imageHeight,
          },
          path: item.data,
        });
        break;
      }
      case "Right Aligned Description": {
        const imageMeasurement = this.calculateImageHeightAndWidth(
          item,
          true,
          pptConfig
        );
        slide.addImage({
          x:
            imageMeasurement.imageX.toFixed(5) > 0
              ? imageMeasurement.imageX
              : imageMeasurement.imageX + 0.2,
          y: imageMeasurement.imageY,
          w: imageMeasurement.imageWidth,
          h: imageMeasurement.imageHeight,
          sizing: {
            type: "contain",
            w: imageMeasurement.imageWidth,
            h: imageMeasurement.imageHeight,
          },
          path: item.data,
        });
        const imageXPostion =
          imageMeasurement.imageX + 0.5 + imageMeasurement.imageWidth - 0.4;
        // const tabOpts1 = { x: imageXPostion, y: imageMeasurement.imageY + imageMeasurement.imageHeight / 2 - (middleSlideFields > 2 ? (imageXPostion + 1.25 > 9 ? 1.55 : 1.125) : ((imageXPostion + 1.25 > 9 ? 0.9 : 0.65))), w: imageXPostion + 1.25 > 9 ? "19%" : "29%", h: middleSlideFields > 2 ? 2.25 : 1.25, fill: 'FFFFFF', color: '3D3D3D', fontSize: 14, align: 'l', valign: 'm', autoPage: false, border: { pt: middleSlideBorder ? 1 : 0 } };
        const tabOpts1 = {
          x: 7.3,
          y:
            imageMeasurement.imageY +
            imageMeasurement.imageHeight / 2 -
            (middleSlideFields > 2
              ? imageXPostion + 1.25 > 9
                ? 1.55
                : 1.125
              : imageXPostion + 1.25 > 9
              ? 0.9
              : 0.65),
          w: "25%",
          h: this.checkWideScreenSlideRatio()
            ? pptConfig.middleSlideFormat === "LESS_DETAILS"
              ? 0.3
              : middleSlideFields > 2
              ? 1
              : 0.75
            : pptConfig.middleSlideFormat === "LESS_DETAILS"
            ? 0.41
            : middleSlideFields > 2
            ? 1.25
            : 1,
          fill: "FFFFFF",
          color: "3D3D3D",
          fontSize: this.checkWideScreenSlideRatio() ? 12 : 14,
          align: "l",
          valign: "m",
          autoPage: false,
          border: {
            pt: middleSlideBorder ? 1 : 0,
            type: middleSlideBorder ? "solid" : "none",
          },
        };

        slide.addTable(arrTabRows1, tabOpts1);
        break;
      }
      default: {
        const imageMeasurement = this.calculateImageHeightAndWidth(
          item,
          null,
          pptConfig
        );
        slide.addImage({
          x: imageMeasurement.imageX,
          y: "4%",
          w: imageMeasurement.imageWidth,
          h: imageMeasurement.imageHeight,
          sizing: {
            type: "contain",
            w: imageMeasurement.imageWidth,
            h: imageMeasurement.imageHeight,
          },
          path: item.data,
        });
        const tabOpts1 = {
          x: 0.5,
          y: this.checkWideScreenSlideRatio() ? 4 : 6,
          w: "90%",
          h: this.checkWideScreenSlideRatio()
            ? pptConfig.middleSlideFormat === "LESS_DETAILS"
              ? 0.3
              : middleSlideFields > 2
              ? 1
              : 0.75
            : pptConfig.middleSlideFormat === "LESS_DETAILS"
            ? 0.41
            : middleSlideFields > 2
            ? 1.25
            : 1,
          fill: "FFFFFF",
          color: "3D3D3D",
          fontSize: this.checkWideScreenSlideRatio() ? 12 : 14,
          align: "l",
          valign: "m",
          autoPage: false,
          border: {
            pt: middleSlideBorder ? 1 : 0,
            type: middleSlideBorder ? "solid" : "none",
          },
        };
        slide.addTable(arrTabRows1, tabOpts1);
        break;
      }
    }
  }

  // get text for dates tab wise
  getOperatedDateText(tabIndex) {
    switch (tabIndex) {
      case 0: {
        // for site tab
        this.operatedDateText = "Start Date";
        break;
      }
      case 1: {
        // for mounting tab
        this.operatedDateText = "Mounting Date";
        break;
      }
      case 2: {
        // for monitoring tab
        this.operatedDateText = "Monitoring Date";
        break;
      }
      case 3: {
        // for unmounting tab
        this.operatedDateText = "Unmounting Date";
        break;
      }
    }
    return this.operatedDateText;
  }

  // setting slide for campaign item
  setItemSlideForCampaign(item, tabIndex) {
    this.tabIndex = tabIndex;
    const slide = this.pptx.addNewSlide();
    const imageMeasurement = this.calculateImageHeightAndWidth(item);

    slide.addImage({
      x: imageMeasurement.imageX,
      y: "4%",
      w: imageMeasurement.imageWidth,
      h: imageMeasurement.imageHeight,
      sizing: {
        type: "contain",
        w: imageMeasurement.imageWidth,
        h: imageMeasurement.imageHeight,
      },
      data: item.data,
    });
    const tabOpts1 = {
      x: 0.5,
      y: this.checkWideScreenSlideRatio()
        ? (this.pptFormat === "Less Detail" || this.pptFormat=="Less Detail(Compress)")
          ? 5
          : 4.3
        : (this.pptFormat === "Less Detail" || this.pptFormat=="Less Detail(Compress)")
        ? 6.8
        : 6,
      w: "90%",
      h: this.checkWideScreenSlideRatio()
        ? (this.pptFormat === "Less Detail" || this.pptFormat=="Less Detail(Compress)")
          ? 0.3
          : 1
        : (this.pptFormat === "Less Detail" ||this.pptFormat=="Less Detail(Compress)")
        ? 0.41
        : 1.25,
      fill: "FFFFFF",
      color: "3D3D3D",
      fontSize: this.checkWideScreenSlideRatio() ? 12 : 14,
      align: "l",
      valign: "m",
      autoPage: false,
      border: { pt: "0", type: "none" },
    };
    const arrTabRows1 = [
      [
        {
          text: item.header,
          options: {
            colspan: 2,
            fill: "E4E9EB",
            margin: [0, 0, 0, 10],
            bold: true,
            align: "center",
          },
        },
      ],
      [
        { text: "Size : " + item.size, options: { margin: [0, 0, 0, 10] } },
        {
          text: "Lights : " + item.lightType,
          options: { margin: [0, 0, 0, 10] },
        },
      ],
      [
        {
          text:
            this.getOperatedDateText(tabIndex) +
            " : " +
            DateUtil.dategridFormatter(item.operatedDate) +
            " " +
            item.mode,
          options: { margin: [0, 0, 0, 10] },
        },
        {
          text:
            "Site End Date : " +
            DateUtil.dategridFormatter(item.availabilityDate),
          options: { margin: [0, 0, 0, 10] },
        },
      ],
    ];
    this.tabIndex === 0
      ? slide.addTable(
          [
            [
              {
                text: item.header,
                options: {
                  colspan: 2,
                  fill: "E4E9EB",
                  margin: [0, 0, 0, 10],
                  bold: true,
                  align: "center",
                },
              },
            ],
          ],
          tabOpts1
        )
      : slide.addTable(arrTabRows1, tabOpts1);
  }

  // calculate image height and width to be displayed in slide
  calculateImageHeightAndWidth(item, format?, pptConfig?) {
    let imageContainerHeight,
      imageContainerWidth,
      imageHeight = 0,
      imageWidth = 0,
      imageX,
      imageY,
      ratio = 0;
    imageContainerHeight = this.checkWideScreenSlideRatio()
      ? this.pptFormat === "Less Detail" || this.pptFormat=="Less Detail(Compress)" ||
        (pptConfig && pptConfig.middleSlideFormat === "LESS_DETAILS")
        ? 4.6
        : 4
      : this.pptFormat === "Less Detail" || this.pptFormat=="Less Detail(Compress)" ||
        (pptConfig && pptConfig.middleSlideFormat === "LESS_DETAILS")
      ? 6
      : 5.3;
    imageContainerWidth = format
      ? this.middleSitesFormat !== "Right Aligned Description"
        ? 8
        : 7
      : 9;

    imageHeight = this.convertEmuToInch(
      this.convertPixelToEmu(item.imageHeight)
    );
    imageWidth = this.convertEmuToInch(this.convertPixelToEmu(item.imageWidth));

    while (imageWidth > imageContainerWidth) {
      ratio = imageContainerWidth / imageWidth;
      imageHeight = imageHeight * ratio;
      imageWidth = imageWidth * ratio;
      if (format) {
        const imageCoordinates = this.setImageLogoToCenter(
          imageWidth,
          imageHeight,
          true
        );
        imageX = imageCoordinates.imageXPosition;
        imageY = imageCoordinates.imageYPosition;
      } else {
        // imageX = this.setImageToCenter(imageWidth);
      }
    }

    if (imageWidth <= imageContainerWidth) {
      // imageX = this.setImageToCenter(imageWidth);
      if (format) {
        const imageCoordinates = this.setImageLogoToCenter(
          imageWidth,
          imageHeight,
          true
        );
        imageX = imageCoordinates.imageXPosition;
        imageY = imageCoordinates.imageYPosition;
      } else {
        // imageX = this.setImageToCenter(imageWidth);
      }
    }

    while (imageHeight > imageContainerHeight) {
      ratio = imageContainerHeight / imageHeight;
      imageWidth = imageWidth * ratio;
      imageHeight = imageHeight * ratio;
      if (format) {
        const imageCoordinates = this.setImageLogoToCenter(
          imageWidth,
          imageHeight,
          true
        );
        imageX = imageCoordinates.imageXPosition;
        imageY = imageCoordinates.imageYPosition;
      } else {
        // imageX = this.setImageToCenter(imageWidth);
      }
    }

    if (imageHeight <= imageContainerHeight) {
      if (format) {
        const imageCoordinates = this.setImageLogoToCenter(
          imageWidth,
          imageHeight,
          true
        );
        imageX = imageCoordinates.imageXPosition;
        imageY = imageCoordinates.imageYPosition;
      } else {
        // imageX = this.setImageToCenter(imageWidth);
      }
    }

    // const widthRatio = imageContainerWidth / imageWidth;
    // const heightRatio = imageContainerHeight / imageHeight;

    // ratio = Math.min(widthRatio,heightRatio);

    // imageWidth *=ratio;
    // imageHeight*=ratio;

    // imageWidth = imageContainerWidth;
    // imageHeight = imageContainerHeight;

    // imageX = this.setImageToCenter(imageWidth);
    // imageY = this.setImageToCenter(imageHeight);

    if (pptConfig && this.middleSitesFormat !== "Right Aligned Description") {
      const imagePosition = this.setImageBasedOnPosition(
        imageX,
        imageY,
        imageWidth,
        imageHeight,
        pptConfig
      );
      imageX = imagePosition.imageX;
      imageY = imagePosition.imageY;
    }
    if (!pptConfig) {
      imageX = this.setImageToCenter(imageWidth);
    }

    return {
      imageWidth: imageWidth,
      imageHeight: imageHeight,
      imageX: imageX,
      imageY: imageY,
    };
  }

  setImageBasedOnPosition(imageX, imageY, imageWidth, imageHeight, pptConfig) {
    switch (pptConfig.middleSlideImagePosition) {
      case "LEFT": {
        imageX = 0.5;
        break;
      }
      case "RIGHT": {
        imageX = this.setPPTLogoXPosition(imageWidth) - 0.5;
        break;
      }
      case "CENTER": {
        imageX = this.setImageToCenter(imageWidth);
        break;
      }
      default: {
        imageX = this.setImageToCenter(imageWidth);
      }
    }
    return { imageX: imageX, imageY: imageY };
  }

  calculatePPTLogoPosition(item, data) {
    this.pptLogoPosition =
      PPTLogoPositionEnum[
        this.pptFormat === "Vendor Specific"
          ? data.logoPosition
          : data.customer.pptConfig.logoPosition
      ];
    let imageContainerHeight,
      imageContainerWidth,
      imageHeight = 0,
      imageWidth = 0,
      imageX,
      imageY,
      ratio = 0;

    imageContainerHeight = this.checkWideScreenSlideRatio() ? 3 : 4;
    imageContainerWidth = 7;

    imageHeight = this.convertEmuToInch(
      this.convertPixelToEmu(item.imageHeight)
    );
    imageWidth = this.convertEmuToInch(this.convertPixelToEmu(item.imageWidth));

    if (imageWidth <= imageContainerWidth) {
      imageX = this.setPPTLogoXPosition(imageWidth);
    }

    while (imageWidth > imageContainerWidth) {
      ratio = imageContainerWidth / imageWidth;
      imageHeight = imageHeight * ratio;
      imageWidth = imageWidth * ratio;
      imageX = this.setPPTLogoXPosition(imageWidth);
    }

    if (imageHeight <= imageContainerHeight) {
      imageY = this.setPPTLogoYPosition(imageHeight);
    }

    while (imageHeight > imageContainerHeight) {
      ratio = imageContainerHeight / imageHeight;
      imageWidth = imageWidth * ratio;
      imageHeight = imageHeight * ratio;
      imageY = this.setPPTLogoYPosition(imageHeight);
    }

    const imagePosition = this.setLogoBasedOnPosition(
      imageX,
      imageY,
      imageWidth,
      imageHeight
    );
    imageX = imagePosition.imageX;
    imageY = imagePosition.imageY;

    return {
      imageWidth: imageWidth,
      imageHeight: imageHeight,
      imageX: imageX,
      imageY: imageY,
    };
  }

  setPPTLogoXPosition(imageWidth) {
    const slideWidth = this.convertEmuToInch(this.layoutWidth);
    return slideWidth - imageWidth;
  }

  setPPTLogoYPosition(imageHeight) {
    const slideHeight = this.convertEmuToInch(this.layoutHeight);
    return slideHeight - imageHeight;
  }

  setImageLogoToCenter(imageWidth, imageHeight, format?) {
    const slideWidth = format
      ? imageWidth < 7.5
        ? this.convertEmuToInch(this.layoutWidth) - 3
        : this.convertEmuToInch(this.layoutWidth) - 2
      : this.convertEmuToInch(this.layoutWidth);
    // const slideWidth = (this.convertEmuToInch(this.layoutWidth));
    const slideHeight = this.convertEmuToInch(this.layoutHeight);

    const slideXCenter = slideWidth / 2;
    const imageXCenter = imageWidth / 2;

    const slideYCenter = slideHeight / 2;
    const imageYCenter = imageHeight / 2;
    return {
      imageXPosition: slideXCenter - imageXCenter,
      imageYPosition: slideYCenter - imageYCenter,
    };
  }

  setLogoBasedOnPosition(imageX, imageY, imageWidth?, imageHeight?) {
    switch (this.pptLogoPosition) {
      case "Top Left": {
        imageX = 0.2;
        imageY = 0.2;
        break;
      }
      case "Bottom Left": {
        imageX = 0.2;
        break;
      }
      case "Top Right": {
        imageY = 0.2;
        break;
      }
      case "Center": {
        const positions = this.setImageLogoToCenter(imageWidth, imageHeight);
        imageX = positions.imageXPosition;
        imageY = positions.imageYPosition;
        break;
      }
    }
    return { imageX: imageX, imageY: imageY };
  }

  setImageToCenter(imageWidth) {
    const slideWidth = this.convertEmuToInch(this.layoutWidth);
    const slideCenter = slideWidth / 2;
    const imageCenter = imageWidth / 2;
    return slideCenter - imageCenter;
  }

  addTermAndConditionSlide(
    termAndConditions,
    employeeData,
    campaignId?,
    isCampaign = false,
    pptConfig?
  ) {
    const format = pptConfig && this.pptFormat ? this.pptFormat : pptConfig;
    switch (format) {
      case "Full Detail":
      case "Full Detail(Compress)":
      case "Less Detail":
      case "Less Detail(Compress)":
      default: {
        const slide = this.pptx.addNewSlide();

        const tenantId = localStorage.getItem("id");
        const campaignRedirectUrl =
          this.portalBaseUrl +
          SIBConstants.PUBLIC_PAGE +
          campaignId +
          AppUrls.SLASH +
          tenantId;
        slide.addShape(this.pptx.shapes.LINE, {
          x: 0,
          y: 0,
          w: 0,
          line: "0088CC",
          lineSize: 6,
          h: "100%",
          align: "left",
        });
        slide.addText("TERMS & CONDITIONS:-", {
          x: 0.5,
          y: 0.5,
          w: 5.5,
          h: 0,
          fontSize: 18,
          margin: 5,
          bold: true,
        });
        slide.addText(termAndConditions, {
          x: 0.5,
          y: 1.5,
          w: 9,
          h: 2,
          fontSize: 16,
          bold: false,
        });
        slide.addShape(this.pptx.shapes.LINE, {
          x: 0.5,
          y: this.checkWideScreenSlideRatio() ? 4.5 : 5.5,
          w: 9,
          h: 0,
          line: "000000",
          lineSize: 1,
        });
        slide.addText(employeeData.fullName, {
          x: 7.5,
          y: this.checkWideScreenSlideRatio() ? 4.75 : 5.75,
          w: 5.5,
          h: 0,
          fontSize: 16,
          margin: 5,
          bold: false,
        });
        slide.addText(employeeData.number, {
          x: 7.5,
          y: this.checkWideScreenSlideRatio() ? 5.1 : 6.1,
          w: 5.5,
          h: 0,
          fontSize: 16,
          margin: 5,
        });
        isCampaign
          ? slide.addText(
              [
                {
                  text: "Campaign Tracker Link",
                  options: {
                    hyperlink: {
                      url: campaignRedirectUrl,
                      tooltip: "Campaign Tracker",
                    },
                  },
                },
              ],
              {
                x: 0.5,
                y: this.checkWideScreenSlideRatio() ? 5.1 : 6.1,
                w: 4.5,
                h: 0,
                fontSize: 14,
                margin: 5,
                bold: false,
              }
            )
          : "";
        break;
      }
      case "Customer Configured":
      case "Vendor Specific": {
        if (pptConfig && pptConfig.footerSlide && pptConfig.footerSlide.url) {
          const slide = this.pptx.addNewSlide();
          slide.background = {
            path: pptConfig.footerSlide.url,
          };
        } else {
          this.addTermAndConditionSlide(
            termAndConditions,
            employeeData,
            campaignId,
            false,
            null
          );
        }
        break;
      }
    }
  }

  savePpt(name) {
    switch (this.tabIndex) {
      case 0: {
        this.pptx
          .writeFile(
            name + "-Sites" + "-" + DateUtil.formatDateDDMMYY(new Date())
          )
          .then((fileName) => {
            this.campaignPptDownloadComplete$.next(fileName);
          });
        break;
      }
      case 1: {
        this.pptx
          .writeFile(
            name + "-Mount" + "-" + DateUtil.formatDateDDMMYY(new Date())
          )
          .then((fileName) => {
            this.campaignPptDownloadComplete$.next(fileName);
          });
        break;
      }
      case 2: {
        this.pptx
          .writeFile(
            name + "-Monitored" + "-" + DateUtil.formatDateDDMMYY(new Date())
          )
          .then((fileName) => {
            this.campaignPptDownloadComplete$.next(fileName);
          });
        break;
      }
      case 3: {
        this.pptx
          .writeFile(
            name + "-Unmount" + "-" + DateUtil.formatDateDDMMYY(new Date())
          )
          .then((fileName) => {
            this.campaignPptDownloadComplete$.next(fileName);
          });
        break;
      }
      default: {
        this.pptx
          .writeFile(name + "-" + DateUtil.formatDateDDMMYY(new Date()))
          .then((fileName) => {
            this.planPptDownloadComplete$.next(fileName);
          });
      }
    }
    this.tabIndex = undefined;
  }

  getBase64ImageFromURL(url: string, pptData) {
    return Observable.create((observer: Observer<any>) => {
      let img = new Image();
      img.crossOrigin = "Anonymous";
      img.src = url;
      if (!img.complete) {
        this.setImageData(img, pptData, observer);
      } else {
        pptData.data = this.getBase64Image(img);
        pptData.imageHeight = img.height;
        pptData.imageWidth = img.width;
        observer.next(pptData);
        observer.complete();
      }
    });
  }

  /**
   * @description Set image data in pptData object after the image has been loaded.
   * PLEASE DO NOT REMOVE COMMENTED CODE IN THIS FUNCTION
   * @author Divya Sachan
   * @date 10/07/2023
   * @param {*} firebaseUrl
   * @param {*} pptData
   * @return {*}
   * @memberof PptGeneratorService
   */
  setImageDataForPPT(firebaseUrl, pptData,compressionQuality:number = 0.7) {
    return Observable.create((observer: Observer<Blob>) => {
      let img = new Image();
      img.crossOrigin = "Anonymous";
      if (firebaseUrl) {
        img.src = firebaseUrl;
      } else {
        if (this.noImageBlobData) {
          pptData.data = this.noImageBlobData.data;
          pptData.height = this.noImageBlobData.height;
          pptData.width = this.noImageBlobData.width;
          observer.next(pptData);
          observer.complete();
        } else {
          img.src = this.noImageUrl;
        }
      }
      img.onload = () => {
        this.setImgDataInPptdata(img, pptData, observer,compressionQuality);
      };

      img.onerror = (err) => {
        if (this.noImageBlobData) {
          pptData.data = this.noImageBlobData.data;
          pptData.height = this.noImageBlobData.height;
          pptData.width = this.noImageBlobData.width;
          observer.next(pptData);
          observer.complete();
        } else {
          img.src = this.noImageUrl;
          img.onload = () => {
            this.setImgDataInPptdata(img, pptData, observer,compressionQuality);
          };
        }
      };
    });
  }

  setImgDataInPptdata(img, pptData, observer,compressionQuality:number = 0.7) {
    let width = img.width;
    let height = img.height;

    const canvas = document.createElement("canvas");

    canvas.width = width;
    canvas.height = height;

    const ctx = canvas.getContext("2d");
    ctx.drawImage(img, 0, 0, width, height);

    canvas.toBlob(
      function (blob) {
        pptData.data = URL.createObjectURL(blob);
        pptData.imageHeight = img.height;
        pptData.imageWidth = img.width;
        observer.next(pptData);
        observer.complete();
      },
      "image/jpeg",
      // 0.7 // Compression quality (0.0 - 1.0) - HERE IT IS 80% QUALITY I.E., 20% COMPRESSED
      compressionQuality
    );
  }

  downloadPPTFromFirebaseUrl(pptData) {
    // start - get base 64 data from url
    var xhr = new XMLHttpRequest();
    xhr.open("GET", pptData.url, true);
    xhr.responseType = "blob";
    xhr.onload = function (e) {
      // console.log(this.response);
      var reader = new FileReader();
      reader.onload = function (event) {
        var res = event.target["result"];
        // console.log(res);
        const blob = new Blob([xhr.response], {
          type: "application/vnd.openxmlformats-officedocument.presentationml.presentation",
        });
        FileSaver.saveAs(blob, pptData.name); // download ppt from blob
      };
      var file = this.response;
      reader.readAsDataURL(file);
    };
    xhr.send();
    // end - get base 64 from url
  }

  setImageData(img, pptData, observer) {
    img.onload = () => {
      pptData.data = this.getBase64Image(img);
      pptData.imageHeight = img.height;
      pptData.imageWidth = img.width;
      observer.next(pptData);
      observer.complete();
    };
    img.onerror = (err) => {
      // observer.error(err);
      const image = new Image();
      image.crossOrigin = "Anonymous";
      image.src = this.noImageUrl;
      this.setImageData(image, pptData, observer);
      // observer.complete();
    };
  }

  getBase64Image(img: HTMLImageElement) {
    const canvas = document.createElement("canvas");
    canvas.width = img.width;
    canvas.height = img.height;
    const ctx = canvas.getContext("2d");
    ctx.drawImage(img, 0, 0);
    const dataURL = canvas.toDataURL("image/png", 1);
    return dataURL.replace(/^data:image\/(png|jpg);base64,/, "");
  }

  dataURItoBLob(dataURI, pptData) {
    return Observable.create((observer: Observer<Blob>) => {
      const byteString = window.atob(dataURI);
      const arrayBuffer = new ArrayBuffer(byteString.length);
      const int8Array = new Uint8Array(arrayBuffer);
      for (let i = 0; i < byteString.length; i++) {
        int8Array[i] = byteString.charCodeAt(i);
      }
      const blob = new Blob([int8Array], { type: "image/jpeg" });
      pptData.data = blob;
      // return blob;
      observer.next(pptData);
      observer.complete();
    });
  }

  blobToFile(blob, pptData) {
    return Observable.create((observer) => {
      const imageFile = new File([blob], "", { type: "image/jpeg" });
      pptData.data = imageFile;
      observer.next(pptData);
    });
  }

  compressFile(file, pptData) {
    return Observable.create((observer) => {
      new Compressor(file, {
        quality: 0.4,
        success: (result) => {
          if (result) {
            pptData.data = result;
            observer.next(pptData);
          }
        },
      });
    });
  }

  getBase64FromFile(file, pptData) {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    return Observable.create((observer) => {
      reader.onload = (e) => {
        pptData.data = e.target["result"];
        observer.next(pptData);
      };
    });
  }

  convertPixelToEmu(data) {
    //1 pixel is equal to 9525emu
    return data * 9525;
  }

  convertEmuToInch(data) {
    return data / 914400;
  }

  setVendorInformation(url, pptText, pptRedirectUrl, portalBaseUrl?) {
    this.logoUrl = url;
    this.pptText = pptText;
    this.pptRedirectUrl = pptRedirectUrl;
    this.portalBaseUrl = portalBaseUrl;
  }

  checkWideScreenSlideRatio() {
    if (this.slideRatio && this.slideRatio === "WIDE_SCREEN") {
      return true;
    } else {
      return false;
    }
  }

  setMonthlyRateLabel() {
    return this.auth.setMonthlyRateLabel();
  }
}
