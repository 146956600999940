import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation,
} from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import * as _ from "lodash";
import { of as observableOf, Subscription } from "rxjs";
import { AuthService } from "../../login-service/auth.service";
import { GridActionButtonConfig } from "../../components/sib-forms/grid/grid-action-button.config";
import { GridComponent } from "../../components/sib-forms/grid/grid.component";
import {
  EditableGridFieldTypes,
  GridColumn,
  GridConfig,
  GridPaginationEvent,
  SelectionMode,
} from "../../components/sib-forms/grid/grid.config";
import { DateUtil } from "../../helpers/date.util";
import * as utils from "../../helpers/utils";
import { CombinedItem } from "../../modals/billings/combined-item";
import { Invoice } from "../../modals/billings/invoice";
import { InvoiceService } from "../../services/shared/invoice.service";
import { SystemService } from "../../services/shared/system.service";
import { TaxConfigurationService } from "../../services/shared/tax-configuration.service";
import { ChargeTypeEnum } from "../../shared/constants/charge-type-enum";
import { HoardingLightEnum } from "../../shared/constants/hoarding-light-enum";
import { InventoryTypeEnum } from "../../shared/constants/inventory-type.enum";
import { InvoiceFormatEnum } from "../../shared/constants/invoice-format-enum";
import { CurrencyHelperPipe } from "../../shared/helpers/currency.pipe/currencyHelperPipe";

@Component({
  selector: "sib-invoice-summary-grid",
  templateUrl: "./invoice-summary-grid.component.html",
  styleUrls: ["./invoice-summary-grid.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class InvoiceSummaryGridComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  @ViewChild("invoiceSummaryGrid")
  invoiceSummaryGridRef: GridComponent<CombinedItem>;

  _invoice: Invoice = new Invoice();
  items: CombinedItem[] = []; // to hold the current invoice items
  previousItems: CombinedItem[] = []; // to hold the previous invoice items
  comparisionItems: CombinedItem[] = []; // to merge the current and previous invoice items
  // to sort each type of item independently
  rentItems: CombinedItem[] = [];
  printingItems: CombinedItem[] = [];
  mountingItems: CombinedItem[] = [];
  previousInvoiceRentItems: CombinedItem[] = [];
  previousInvoicePrintingItems: CombinedItem[] = [];
  previousInvoiceMountingItems: CombinedItem[] = [];
  customSerialNumber = 0;
  invoiceRowsOrder: any[] = [];
  sorting = false;
  @Input() set invoice(invoice) {
    this._invoice = invoice;
    this._invoice = JSON.parse(JSON.stringify(this._invoice));
    this._invoiceFormat = this._invoice.invoiceFormat;
    // this.grouped = this._invoice.grouped;
  }

  get invoice() {
    return this._invoice;
  }

  _tempInvoice: Invoice;
  @Input() set tempInvoice(tempInvoice) {
    this._tempInvoice = tempInvoice;
    this._tempInvoice = JSON.parse(JSON.stringify(this._tempInvoice));
    this.tempInvoiceFormat = this._tempInvoice.invoiceFormat;
    this.setItems();
    this.setPreviousInvoiceItems();
  }

  get tempInvoice() {
    return this._tempInvoice;
  }

  _showChanges = false;
  @Input() set showChanges(showChanges) {
    this._showChanges = showChanges;
    this._showChanges = JSON.parse(JSON.stringify(this._showChanges));
    this.setColumns();

    if (this.gridConfig.dataLoadFunction !== undefined) {
      this.invoiceSummaryGridRef.refresh();
    }
  }

  get showChanges() {
    return this._showChanges;
  }

  _revert = false;
  @Input() set revert(revert) {
    this._revert = revert;
    this._revert = JSON.parse(JSON.stringify(this._revert));
    if (this._revert) {
      this.setItems();
    }
  }

  get revert() {
    return this._revert;
  }

  _grouped = false;
  @Input() set grouped(grouped) {
    this._grouped = grouped;
    this.setItems();
    // this.setPreviousInvoiceItems();
    if (this.gridConfig.dataLoadFunction !== undefined) {
      this.invoiceSummaryGridRef.refresh();
    }
  }

  get grouped() {
    return this._grouped;
  }

  _invoiceFormat: InvoiceFormatEnum;
  @Input() set invoiceFormat(invoiceFormat) {
    if (invoiceFormat) {
      this._invoiceFormat = invoiceFormat;
      this._invoiceFormat = JSON.parse(JSON.stringify(this._invoiceFormat));
    }
    this.setColumns();
  }

  get invoiceFormat() {
    return this._invoiceFormat;
  }

  // _sortedRows: any[];
  // @Input() set sortedRows(sortedRows) {
  //     this._sortedRows = sortedRows;

  // }

  // get sortedRows() {
  //     return this._sortedRows;
  // }

  @Input() isInvFormatFullDetail: boolean;

  _showChildren = false;
  @Input() set showChildren(showChildren) {
    this._showChildren = showChildren;
    this.setItems();

    // const rows = this.sortedRows.map(item => item.id);
    // const rowsOrder = rows.length > 0 ? rows : this._invoice.rowsOrder;
    // if (rowsOrder.length > 0) {
    //     const orderedItems = [];
    //     rowsOrder.forEach(id => {
    //         const item = this.items.find(itm => itm.id === id);
    //         const index = this.items.indexOf(item);
    //         orderedItems.push(item);

    //         if (this._showChildren) {
    //             const unitsLength = item.childInventories.length;
    //             for (let i = index + 1; i <= index + unitsLength; i++) {
    //                 orderedItems.push(this.items[i]);
    //             }
    //         }
    //     });

    //     this.items = JSON.parse(JSON.stringify(orderedItems));
    // }

    if (this.gridConfig.dataLoadFunction !== undefined) {
      this.invoiceSummaryGridRef.refresh();
    }
  }
  // @Input() showChildren: boolean;

  /**
   * @description to emit the rows for reorder
   * @type {EventEmitter<Array<any>>}
   * @memberof InvoiceSummaryGridComponent
   */
  @Output() eEmitRowsToRearrange: EventEmitter<Array<any>> = new EventEmitter();

  isLoading = false;
  subscription: Subscription[] = [];

  igstParameter: number;
  gstParameter: number;
  cgstParameter: number;
  sgstParameter: number;

  defaultSortInProgress = false;

  // for amount field in package rate
  firstRentalItem = true;
  firstPrintingMountingItem = true;
  firstPreviusRentalItem = true;
  firstPreviousPrintingMountingItem = true;
  firstHSNRentalItem = true;
  firstHSNPrintingItem = true;
  firstHSNMountingItem = true;

  selectedChargeType: Map<string, string> = new Map();
  onlyGroupItemsId: any;
  tempInvoiceFormat: InvoiceFormatEnum;

  columns: Array<GridColumn> = [
    {
      field: "srNo",
      name: "srNo",
      header: "Sr ",
      required: true,
      width: "2vw",
      editable: false,
      hidden: false,
      permanent: true,
      default: true,
      sortable: false,
      styleClass: "sr-number",
      type: EditableGridFieldTypes.CUSTOM,
    },
    {
      field: "inventory",
      name: "inventory",
      header: "Description",
      required: true,
      editable: false,
      hidden: false,
      width: "25vw",
      default: true,
      styleClass: "word-break-wrap",
      permanent: true,
      sortable: false,
      type: EditableGridFieldTypes.CUSTOM,
    },
    {
      field: "hsnCode",
      name: "hsnCode",
      header: "HSN Code",
      required: true,
      editable: false,
      default: true,
      styleClass: "invoice-grid",
      hidden: true,
      permanent: true,
      type: EditableGridFieldTypes.CUSTOM,
      width: "5vw",
      sortable: false,
      // displayFn: (data) => {
      //     if (data.hsnCode > 0) {
      //         return data.hsnCode;
      //     } else {
      //         return '';
      //     }
      // }
    },
    {
      field: "itemStartDate",
      name: "itemStartDate",
      header: "Start Date",
      required: true,
      editable: false,
      hidden: false,
      width: "4vw",
      default: true,
      styleClass: "word-break-wrap",
      permanent: true,
      sortable: true,
      type: EditableGridFieldTypes.CUSTOM,
    },
    {
      field: "itemEndDate",
      name: "itemEndDate",
      header: "End Date",
      required: true,
      editable: false,
      hidden: false,
      width: "4vw",
      default: true,
      styleClass: "word-break-wrap",
      permanent: true,
      sortable: true,
      type: EditableGridFieldTypes.CUSTOM,
    },
    {
      field: "days",
      name: "days",
      header: "Days",
      required: true,
      editable: false,
      default: true,
      hidden: false,
      permanent: true,
      type: EditableGridFieldTypes.CUSTOM,
      width: "2vw",
      sortable: true,
    },
    {
      field: "rate",
      name: "rate",
      header: "Monthly Rate",
      required: true,
      editable: false,
      default: true,
      styleClass: "invoice-grid",
      hidden: false,
      permanent: true,
      type: EditableGridFieldTypes.CUSTOM,
      width: "4vw",
      sortable: true,
      displayFn: (data) => {
        if (data.rate > 0) {
          return data.rate;
        } else {
          return "-";
        }
      },
    },

    {
      field: "amount",
      name: "amount",
      header: "Cost",
      permanent: true,
      sortable: true,
      hidden: false,
      required: true,
      editable: false,
      width: "4vw",
      type: EditableGridFieldTypes.CUSTOM,
      styleClass: "invoice-grid",
    },
  ];

  totalRecords: number;
  invoiceSummaryGroup: FormGroup;
  gridConfig: GridConfig<CombinedItem> = new GridConfig<CombinedItem>();
  buttonConfig: GridActionButtonConfig;

  total = 0;
  igstTax = 0;
  cgstTax = 0;
  sgstTax = 0;
  grandTotal = 0;

  bothInvoiceRentItems: CombinedItem[] = [];
  bothPreviousInvoiceRentItems: CombinedItem[] = [];
  bothInvoicePrintingItems: CombinedItem[] = [];
  bothPreviousInvoicePrintingItems: CombinedItem[] = [];
  bothInvoiceMountingItems: CombinedItem[] = [];
  bothPreviousInvoiceMountingItems: CombinedItem[] = [];
  // for comparision items for invoice formats including 'Ro'
  bothInvoiceRoItems: CombinedItem[] = [];
  bothPreviousInvoiceRoItems: CombinedItem[] = [];
  bothInvoiceRoRentItems: CombinedItem[] = [];
  bothPreviousInvoiceRoRentItems: CombinedItem[] = [];
  bothInvoiceRoPrintMountItems: CombinedItem[] = [];
  bothPreviousInvoiceRoPrintMountItems: CombinedItem[] = [];

  bothPreviousInvoiceAnnexureRentItems: CombinedItem[] = [];
  bothPreviousInvoiceAnnexurePrintingItems: CombinedItem[] = [];
  bothPreviousInvoiceAnnexureMountingItems: CombinedItem[] = [];

  hsnCodeTemplatePresent = false;
  configurations: any[] = [];

  constructor(
    private currencyPipe: CurrencyHelperPipe,
    private taxConfigurationService: TaxConfigurationService,
    private systemService: SystemService,
    private invoiceService: InvoiceService,
    private auth: AuthService
  ) {}

  ngOnInit() {
    this.setFormGroupObjects();
    this.getTaxConfiguration();
    this.setGridConfigObject();
    this.checkForHSNCodeTemplate();
  }

  ngAfterViewInit() {
    if (this.gridConfig.dataLoadFunction !== undefined) {
      this.invoiceSummaryGridRef.refresh();
    }
  }

  checkForHSNCodeTemplate() {
    this.invoiceService.$hsnCodeTemplateFetching.next(true);
    this.systemService
      .get({ module: "BILLING" }, "/search/by-module")
      .subscribe((configurations) => {
        this.configurations = configurations;
        const invoiceTemplateValue = configurations.find(
          (param) => param.key === "INVOICE_TEMPLATE"
        );
        invoiceTemplateValue.value === "WITH_HSN_CODE"
          ? (this.hsnCodeTemplatePresent = true)
          : (this.hsnCodeTemplatePresent = false);

        this.setColumns();
        this.setItems();
        this.invoiceService.$hsnCodeTemplateFetching.next(false);
      });
  }

  setFormGroupObjects() {
    this.invoiceSummaryGroup = new FormGroup({
      invoiceSummaryGridControl: new FormControl(),
    });
  }

  setGridConfigObject() {
    this.gridConfig.getDefaultModel = () => new CombinedItem();
    this.gridConfig.model = CombinedItem;
    this.gridConfig.selectionMode = SelectionMode.Multi;
    this.gridConfig.editable = true;
    this.gridConfig.expandableRows = false;
    this.gridConfig.checkboxSelection = false;
    this.gridConfig.resizableColumns = false;
    this.gridConfig.shouldAddRowOnDelete = false;
    this.gridConfig.showColorCode = false;
    this.gridConfig.lazy = false;
    this.gridConfig.enablePagination = false;
    this.gridConfig.paginatorConfig.alwaysShowPaginator = false;
    this.gridConfig.scrollable = true;
    // this.gridConfig.showLoader = true;

    this.gridConfig.dataLoadFunction = (
      paginationEvent: GridPaginationEvent
    ) => {
      // for rendering the data on the screen
      if (this.showChanges) {
        this.setSerialNumber(this.comparisionItems);
        return observableOf(this.comparisionItems);
      } else {
        if (
          this.hsnCodeTemplatePresent &&
          !this.sorting &&
          (this._invoice.rowsOrder.length > 0 ||
            this.invoiceRowsOrder.length > 0) &&
          InvoiceFormatEnum[this.invoiceFormat] !==
            "As Per Ro inclusive of Printing/Mounting" &&
          InvoiceFormatEnum[this.invoiceFormat] !==
            "As Per Ro with separate Printing/Mounting"
        ) {
          this.setItemsAfterRowRearrangement();
        }
        this.setSerialNumber(this.items);
        return observableOf(this.items);
      }
    };
  }

  setSerialNumber(items) {
    this.customSerialNumber = 0;
    items.forEach((item) => {
      if (item.amount) {
        item.serialNumber = ++this.customSerialNumber;
      }
    });
  }

  getTaxConfiguration() {
    this.taxConfigurationService.getTaxConfiguration();

    this.subscription.push(
      this.taxConfigurationService.gst.subscribe((gst) => {
        this.gstParameter = gst;
      })
    );

    this.subscription.push(
      this.taxConfigurationService.cgst.subscribe((cgst) => {
        this.cgstParameter = cgst;
      })
    );

    this.subscription.push(
      this.taxConfigurationService.sgst.subscribe((sgst) => {
        this.sgstParameter = sgst;
      })
    );

    this.subscription.push(
      this.taxConfigurationService.igst.subscribe((igst) => {
        this.igstParameter = igst;
        if (
          this._invoice.rentItems.length > 0 ||
          this._invoice.printingItems.length > 0 ||
          this._invoice.mountingItems.length > 0
        ) {
          this.setItems();
        }
      })
    );
  }

  setItems() {
    this.items = [];
    this.total = 0;
    this.igstTax = 0;
    this.cgstTax = 0;
    this.sgstTax = 0;
    this.grandTotal = 0;

    this.setItemsBasedOnInvoiceFormat();

    if (this.validateInvioceRowsOrder() && !this.defaultSortInProgress) {
      if (this.items.length === this._invoice.rowsOrder.length) {
        this.updateItemsOrder(this._invoice.rowsOrder, true);
      }
    }

    if (this.gridConfig.dataLoadFunction !== undefined) {
      this.invoiceSummaryGridRef.refresh();
    }
    if (this.tempInvoice && this.tempInvoice.id) {
      this.setPreviousInvoiceItems();
    }
  }

  setItemsBasedOnInvoiceFormat() {
    this.items = [];
    this.customSerialNumber = 0;
    this.onlyGroupItemsId = [];
    this.setRentItems();
    this.setPrintingItems();
    this.setMountingItems();
    this.sortItems();
    switch (
      this.isInvFormatFullDetail
        ? InvoiceFormatEnum[this._invoiceFormat]
        : InvoiceFormatEnum[this.invoice.invoiceFormat]
    ) {
      case "Full Detail":
      case "Full Detail(Compress)":
      case "Less Detail":
      case "Less Detail(Compress)":
      case "Package Rate inclusive of Printing/Mounting":
      case "Package Rate with separate Printing/Mounting": {
        this.items = [
          ...this.rentItems,
          ...this.printingItems,
          ...this.mountingItems,
        ];
        break;
      }
      case "As Per Ro inclusive of Printing/Mounting": {
        const item: CombinedItem = new CombinedItem();
        item.type = "RO";
        item.id = "As Per Ro";
        item.amount =
          this.rentItems
            .map((rentItem) => rentItem.amount)
            .reduce((sum, rentItem) => sum + rentItem, 0) +
          this.printingItems
            .map((printItem) => printItem.amount)
            .reduce((sum, printItem) => sum + printItem, 0) +
          this.mountingItems
            .map((mountItem) => mountItem.amount)
            .reduce((sum, mountItem) => sum + mountItem, 0);
        this.items = [item];
        break;
      }
      case "As Per Ro with separate Printing/Mounting": {
        const item1: CombinedItem = new CombinedItem();
        const item2: CombinedItem = new CombinedItem();
        item1.type = "RO_RENT";
        item1.amount = this.rentItems
          .map((rentItem) => rentItem.amount)
          .reduce((sum, rentItem) => sum + rentItem, 0);
        item1.id = "As Per Ro - Rent";
        item2.type = "RO_PRINT_MOUNT";
        item2.amount =
          this.printingItems
            .map((printItem) => printItem.amount)
            .reduce((sum, printItem) => sum + printItem, 0) +
          this.mountingItems
            .map((mountItem) => mountItem.amount)
            .reduce((sum, mountItem) => sum + mountItem, 0);
        item2.id = "As Per Ro - Printing/Mounting";
        this.items = [item1, item2];
        break;
      }
      case "As Per Annexure": {
        const item1: CombinedItem = new CombinedItem();
        const item2: CombinedItem = new CombinedItem();
        const item3: CombinedItem = new CombinedItem();
        item1.type = "ANNEXURE";
        item1.amount = this.rentItems
          .map((rentItem) => rentItem.amount)
          .reduce((sum, rentItem) => sum + rentItem, 0);
        item1.id = "As Per Attached Annexure";
        item1.hsnCode = this.getRentHSNCode();

        item2.type = "PRINTING_CHARGES";
        item2.amount = this.printingItems
          .map((printItem) => printItem.amount)
          .reduce((sum, printItem) => sum + printItem, 0);
        item2.id = "Printing Charges";
        item2.hsnCode = this.getPrintHSNCode();

        item3.type = "MOUNTING_CHARGES";
        item3.amount = this.mountingItems
          .map((mountItem) => mountItem.amount)
          .reduce((sum, mountItem) => sum + mountItem, 0);
        item3.id = "Mounting Charges";
        item3.hsnCode = this.getMountHSNCode();
        this.items = [];
        item1.amount > 0 ? this.items.push(item1) : null;
        item2.amount > 0 ? this.items.push(item2) : null;
        item3.amount > 0 ? this.items.push(item3) : null;
        break;
      }

      default: {
        this.items = [
          ...this.rentItems,
          ...this.printingItems,
          ...this.mountingItems,
        ];
      }
    }
    this.items = JSON.parse(JSON.stringify(this.items));
  }

  setRentItems() {
    this.rentItems = [];
    const rentHSNCode = this.getRentHSNCode();
    this._invoice.rentItems.forEach((item, index) => {
      this.rentItems.push(
        new CombinedItem().setRentItem(item, "Rent", rentHSNCode, index)
      );
      this.onlyGroupItemsId.push(item.id);
      if (item.childInventories.length && this._showChildren) {
        item.childInventories.forEach((child) => {
          this.rentItems.push(new CombinedItem().setRentItem(child, "Rent"));
        });
      }
    });
  }

  setPrintingItems() {
    this.printingItems = [];
    const printHSNCode = this.getPrintHSNCode();

    if (this.grouped) {
      let firstPrintingItem = true;
      this.getPrintingItemMapByPrintRate(this.invoice.printingItems).forEach(
        (value, key) => {
          const item = new CombinedItem().setPrintingMountingItem(
            value.type,
            key,
            value,
            printHSNCode,
            firstPrintingItem ? 0 : null
          );
          this.printingItems.push(item);
          this.onlyGroupItemsId.push(item.id);
          if (firstPrintingItem) {
            firstPrintingItem = false;
          }
        }
      );

      this.getPrintingItemMapByPrintRateForUnits(
        this.invoice.printingItems
      ).forEach((value, key) => {
        const item = new CombinedItem().setPrintingMountingItem(
          value.type,
          key,
          value,
          printHSNCode,
          firstPrintingItem ? 0 : null
        );
        this.printingItems.push(item);
        this.onlyGroupItemsId.push(item.id);
        if (firstPrintingItem) {
          firstPrintingItem = false;
        }
      });
    } else {
      let firstPrintingItem = true;
      this._invoice.printingItems.forEach((item, index) => {
        if (item.amount > 0) {
          this.onlyGroupItemsId.push(item.id);

          this.printingItems.push(
            new CombinedItem().setAdditionalItem(
              item,
              "Printing",
              null,
              null,
              null,
              null,
              printHSNCode,
              firstPrintingItem ? 0 : index
            )
          );
          if (item.childInventories.length && this._showChildren) {
            item.childInventories.forEach((child) => {
              this.printingItems.push(
                new CombinedItem().setAdditionalItem(
                  child,
                  "Printing",
                  item.lightType,
                  item.printingPrice,
                  null,
                  true
                )
              );
            });
          }
          if (firstPrintingItem) {
            firstPrintingItem = false;
          }
        }
      });
    }

    if (this.printingItems.length) {
      this.printingItems.forEach((item) => {
        if (
          !this.selectedChargeType.has(item.mediaType) &&
          item.printingPrice
        ) {
          item.printingPrice.perUnit
            ? this.selectedChargeType.set(
                item.mediaType,
                ChargeTypeEnum["PER_UNIT"]
              )
            : this.selectedChargeType.set(
                item.mediaType,
                ChargeTypeEnum["PER_SQFT"]
              );
        }
      });
    }
  }

  sortItems() {
    this.sortRentItems();
    this.sortPrintingItems();
    this.sortMountingItems();
  }

  setMountingItems() {
    this.mountingItems = [];
    const mountHSNCode = this.getMountHSNCode();

    if (this.grouped) {
      let mountFirstItem = true;

      this.getMountingItemMapByPricePerSqft(this.invoice.mountingItems).forEach(
        (value, key) => {
          const item = new CombinedItem().setPrintingMountingItem(
            value.type,
            key,
            value,
            mountHSNCode,
            mountFirstItem ? 0 : null
          );
          this.mountingItems.push(item);
          this.onlyGroupItemsId.push(item.id);
          if (mountFirstItem) {
            mountFirstItem = false;
          }
        }
      );
      this.getMountingItemMapByPerUnit(this.invoice.mountingItems).forEach(
        (value, key) => {
          const item = new CombinedItem().setPrintingMountingItem(
            value.type,
            key,
            value,
            mountHSNCode,
            mountFirstItem ? 0 : null
          );
          this.mountingItems.push(item);
          this.onlyGroupItemsId.push(item.id);
          if (mountFirstItem) {
            mountFirstItem = false;
          }
        }
      );
    } else {
      let mountFirstItem = true;
      this._invoice.mountingItems.forEach((item, index) => {
        if (item.amount > 0) {
          this.onlyGroupItemsId.push(item.id);

          this.mountingItems.push(
            new CombinedItem().setAdditionalItem(
              item,
              "Mounting",
              null,
              null,
              null,
              null,
              mountHSNCode,
              mountFirstItem ? 0 : index
            )
          );
          if (item.childInventories.length && this._showChildren) {
            item.childInventories.forEach((child) => {
              this.mountingItems.push(
                new CombinedItem().setAdditionalItem(
                  child,
                  "Mounting",
                  item.lightType,
                  null,
                  item.mountingPrice
                )
              );
            });
          }
          if (mountFirstItem) {
            mountFirstItem = false;
          }
        }
      });
    }

    if (this.mountingItems.length) {
      this.mountingItems.forEach((item) => {
        if (
          !this.selectedChargeType.has(item.mediaType) &&
          item.mountingPrice
        ) {
          item.mountingPrice.pricePerUnit
            ? this.selectedChargeType.set(
                item.mediaType,
                ChargeTypeEnum["PER_UNIT"]
              )
            : this.selectedChargeType.set(
                item.mediaType,
                ChargeTypeEnum["PER_SQFT"]
              );
        }
      });
    }
  }

  /**
   * @description checks for charge type present
   * @author Raveena Nathani
   * @date 2020-03-03
   * @param {*} rowData
   * @returns
   * @memberof InvoiceSummaryGridComponent
   */
  checkChargeType(rowData) {
    return this.selectedChargeType.get(rowData.mediaType) ===
      ChargeTypeEnum["PER_SQFT"]
      ? "SQ.FT"
      : "Unit";
  }

  /**
   * @description checks whether show total squarefeets or total units
   * @author Raveena Nathani
   * @date 2020-03-03
   * @param {*} rowData
   * @memberof InvoiceSummaryGridComponent
   */
  checkForTotalSquareFeetOrUnit(rowData) {
    return this.selectedChargeType.get(rowData.mediaType) ===
      ChargeTypeEnum["PER_SQFT"]
      ? rowData.squareFeet
      : rowData.units;
  }

  sortRentItems() {
    const rentHSNCode = this.getRentHSNCode();

    // if (!this._showChildren) {
    this.rentItems = utils.sortArray(this.rentItems, "city");

    this.rentItems.forEach((item, index) => {
      index === 0 ? (item.hsnCode = rentHSNCode) : (item.hsnCode = null);
    });
    // } else {
    //     this.setRentItemsWithChildrenOnSort();
    // }
  }

  setRentItemsWithChildrenOnSort() {
    let invoiceRentItems = [];
    const childrenRentItems = [];
    this.rentItems.forEach((item) => {
      if (item.serialNumber) {
        invoiceRentItems.push(item);
      } else {
        childrenRentItems.push(item);
      }
    });
    invoiceRentItems = utils.sortArray(invoiceRentItems, "city");
  }
  sortPrintingItems() {
    this.printingItems = utils.sortArray(this.printingItems, "city");

    const printHSNCode = this.getPrintHSNCode();
    this.printingItems.forEach((item, index) => {
      index === 0 ? (item.hsnCode = printHSNCode) : (item.hsnCode = null);
    });
  }

  sortMountingItems() {
    this.mountingItems = utils.sortArray(this.mountingItems, "city");

    const mountHSNCode = this.getMountHSNCode();
    this.mountingItems.forEach((item, index) => {
      index === 0 ? (item.hsnCode = mountHSNCode) : (item.hsnCode = null);
    });
  }

  setColumns(calledFromThisComponent = true) {
    const invoiceFormat = !this.showChanges
      ? this._invoiceFormat
      : InvoiceFormatEnum[this._invoiceFormat] ===
          InvoiceFormatEnum["FULL_DETAIL"] ||
        InvoiceFormatEnum[this._tempInvoice.invoiceFormat] ===
          InvoiceFormatEnum["FULL_DETAIL"]
      ? "FULL_DETAIL"
      : this._invoiceFormat;
    this.columns.filter((column) => {
      switch (InvoiceFormatEnum[invoiceFormat]) {
        case "Full Detail":
        case "Full Detail(Compress)": {
          column.hidden = false;
          switch (column.field) {
            case "srNo": {
              column.width = "2%";
              break;
            }
            case "inventory": {
              column.width = !this.hsnCodeTemplatePresent ? "38%" : "33%";
              column.sortable = !this.showChanges ? true : false;
              break;
            }
            case "hsnCode": {
              column.width = "5%";
              column.hidden = !this.hsnCodeTemplatePresent ? true : false;
              break;
            }
            case "itemStartDate": {
              column.width = "5%";
              column.sortable = !this.showChanges ? true : false;

              break;
            }
            case "itemEndDate": {
              column.width = "5%";
              column.sortable = !this.showChanges ? true : false;

              break;
            }
            case "days": {
              column.width = "3%";
              column.sortable = !this.showChanges ? true : false;

              break;
            }
            case "rate": {
              column.width = "7%";
              column.sortable = !this.showChanges ? true : false;
              column.header = this.setMonthlyRateHeader();
              break;
            }
            case "amount": {
              column.header = "Cost";
              column.width = "6%";
              column.sortable = !this.showChanges ? true : false;

              break;
            }
          }
          break;
        }
        case "Less Detail":
        case "Less Detail(Compress)":
        case "As Per Ro inclusive of Printing/Mounting":
        case "As Per Ro with separate Printing/Mounting":
        case "As Per Annexure": {
          switch (column.field) {
            case "itemStartDate":
            case "itemEndDate":
            case "days":
            case "rate": {
              column.hidden = true;
              column.header = this.setMonthlyRateHeader();
              break;
            }
            case "srNo": {
              column.width = "2%";
              break;
            }
            case "inventory": {
              column.width = !this.hsnCodeTemplatePresent ? "48%" : "43%";
              column.sortable = !this.showChanges ? true : false;

              break;
            }
            case "hsnCode": {
              column.width = "5%";
              column.hidden =
                !this.hsnCodeTemplatePresent ||
                InvoiceFormatEnum[invoiceFormat] ===
                  "As Per Ro inclusive of Printing/Mounting" ||
                InvoiceFormatEnum[invoiceFormat] ===
                  "As Per Ro with separate Printing/Mounting"
                  ? true
                  : false;

              break;
            }
            case "amount": {
              column.header = "Cost";
              column.width = "5%";
              column.sortable = !this.showChanges ? true : false;

              break;
            }
            default: {
              column.hidden = false;
            }
          }
          break;
        }
        case "Package Rate inclusive of Printing/Mounting":
        case "Package Rate with separate Printing/Mounting": {
          switch (column.field) {
            case "itemStartDate":
            case "itemEndDate":
            case "days":
            case "rate": {
              column.hidden = true;
              column.header = this.setMonthlyRateHeader();
              break;
            }
            case "srNo": {
              column.width = "2%";
              break;
            }
            case "inventory": {
              column.width = !this.hsnCodeTemplatePresent ? "48%" : "43%";
              column.sortable = !this.showChanges ? true : false;

              break;
            }
            case "hsnCode": {
              column.width = "5%";
              column.hidden = !this.hsnCodeTemplatePresent ? true : false;

              break;
            }
            case "amount": {
              column.header = "Package Rate";
              column.width = "5%";
              column.sortable = !this.showChanges ? true : false;

              break;
            }
            default: {
              column.hidden = false;
            }
          }
          break;
        }

        default: {
          column.hidden = false;
          switch (column.field) {
            case "srNo": {
              column.width = "2%";
              break;
            }
            case "inventory": {
              column.width = "38%";
              break;
            }
            case "itemStartDate": {
              column.width = "5%";
              break;
            }
            case "itemEndDate": {
              column.width = "5%";
              break;
            }
            case "days": {
              column.width = "3%";
              break;
            }
            case "rate": {
              column.width = "7%";
              column.header = this.setMonthlyRateHeader();
              break;
            }
            case "amount": {
              column.header = "Cost";
              column.width = "6%";
              break;
            }
          }
        }
      }
      return column;
    });
    if (calledFromThisComponent) {
      this.setItems();
    }
    this.invoiceSummaryGridRef.setAllColumns();
  }

  setPreviousInvoiceItems() {
    this.previousItems = [];
    this.customSerialNumber = 0;
    this.setPreviousInvoiceItemsBasedOnInvoiceFormat();
    if (this.validateInvioceRowsOrder() && !this.defaultSortInProgress) {
      this.updatePreviousItemsOrder(this._invoice.rowsOrder);
    }
    if (this.items) {
      this.createComparisionItems();
    }
  }

  setPreviousInvoiceItemsBasedOnInvoiceFormat() {
    this.setPreviousInvoiceRentItems();
    this.setPreviousInvoicePrintingItems();
    this.setPreviousInvoiceMountingItems();
    this.sortPreviousInvoiceItems();
    switch (InvoiceFormatEnum[this._tempInvoice.invoiceFormat]) {
      case "Full Detail":
      case "Full Detail(Compress)":
      case "Less Detail":
      case "Less Detail(Compress)":
      case "Package Rate inclusive of Printing/Mounting":
      case "Package Rate with separate Printing/Mounting": {
        this.previousItems = [
          ...this.previousInvoiceRentItems,
          ...this.previousInvoicePrintingItems,
          ...this.previousInvoiceMountingItems,
        ];
        break;
      }
      case "As Per Ro inclusive of Printing/Mounting": {
        const item: CombinedItem = new CombinedItem();
        item.type = "RO";
        item.id = "As Per Ro";
        item.amount =
          this.previousInvoiceRentItems
            .map((rentItem) => rentItem.amount)
            .reduce((sum, rentItem) => sum + rentItem, 0) +
          this.previousInvoicePrintingItems
            .map((printItem) => printItem.amount)
            .reduce((sum, printItem) => sum + printItem, 0) +
          this.previousInvoiceMountingItems
            .map((mountItem) => mountItem.amount)
            .reduce((sum, mountItem) => sum + mountItem, 0);
        this.previousItems = [item];
        break;
      }
      case "As Per Ro with separate Printing/Mounting": {
        const item1: CombinedItem = new CombinedItem();
        const item2: CombinedItem = new CombinedItem();
        item1.type = "RO_RENT";
        item1.amount = this.previousInvoiceRentItems
          .map((rentItem) => rentItem.amount)
          .reduce((sum, rentItem) => sum + rentItem, 0);
        item1.id = "As Per Ro - Rent";
        item2.type = "RO_PRINT_MOUNT";
        item2.amount =
          this.previousInvoicePrintingItems
            .map((printItem) => printItem.amount)
            .reduce((sum, printItem) => sum + printItem, 0) +
          this.previousInvoiceMountingItems
            .map((mountItem) => mountItem.amount)
            .reduce((sum, mountItem) => sum + mountItem, 0);
        item2.id = "As Per Ro - Printing/Mounting";
        this.previousItems = [item1, item2];
        break;
      }
      case "As Per Annexure": {
        const item1: CombinedItem = new CombinedItem();
        const item2: CombinedItem = new CombinedItem();
        const item3: CombinedItem = new CombinedItem();

        item1.type = "ANNEXURE";
        item1.amount = this.previousInvoiceRentItems
          .map((rentItem) => rentItem.amount)
          .reduce((sum, rentItem) => sum + rentItem, 0);
        item1.id = "As Per Attached Annexure";
        item1.hsnCode = this.getRentHSNCode();

        item2.type = "PRINTING_CHARGES";
        item2.amount = this.previousInvoicePrintingItems
          .map((printItem) => printItem.amount)
          .reduce((sum, printItem) => sum + printItem, 0);
        item2.id = "Printing Charges";
        item2.hsnCode = this.getPrintHSNCode();

        item3.type = "MOUNTING_CHARGES";
        item3.amount = this.previousInvoiceMountingItems
          .map((mountItem) => mountItem.amount)
          .reduce((sum, mountItem) => sum + mountItem, 0);
        item3.id = "Mounting Charges";
        item3.hsnCode = this.getMountHSNCode();
        // this.previousItems = item2.amount > 0 && item3.amount > 0 ? [item1, item2, item3] : (item2.amount <= 0 && item3.amount > 0 ? [item1, item3] : (item3.amount <= 0 && item2.amount > 0 ? [item1, item2] : [item1]));

        this.previousItems = [];
        item1.amount > 0 ? this.previousItems.push(item1) : null;
        item2.amount > 0 ? this.previousItems.push(item2) : null;
        item3.amount > 0 ? this.previousItems.push(item3) : null;
        // this.previousItems = [item1, item2, item3];
        break;
      }

      default: {
        this.previousItems = [
          ...this.previousInvoiceRentItems,
          ...this.previousInvoicePrintingItems,
          ...this.previousInvoiceMountingItems,
        ];
      }
    }
    this.previousItems = JSON.parse(JSON.stringify(this.previousItems));
  }

  setPreviousInvoiceRentItems() {
    this.previousInvoiceRentItems = [];
    const rentHSNCode = this.getRentHSNCode();
    this.tempInvoice.rentItems.forEach((item, index) => {
      this.previousInvoiceRentItems.push(
        new CombinedItem().setRentItem(item, "Rent", rentHSNCode, index)
      );
      if (item.childInventories.length && this._showChildren) {
        item.childInventories.forEach((child) => {
          this.previousInvoiceRentItems.push(
            new CombinedItem().setRentItem(child, "Rent")
          );
        });
      }
    });
  }

  setPreviousInvoicePrintingItems() {
    this.previousInvoicePrintingItems = [];
    const printHSNCode = this.getPrintHSNCode();
    if (this.grouped) {
      let firstPrintingItem = true;
      this.getPrintingItemMapByPrintRate(
        this.tempInvoice.printingItems
      ).forEach((value, key) => {
        this.previousInvoicePrintingItems.push(
          new CombinedItem().setPrintingMountingItem(
            value.type,
            key,
            value,
            printHSNCode,
            firstPrintingItem ? 0 : null
          )
        );
        if (firstPrintingItem) {
          firstPrintingItem = false;
        }
      });
      this.getPrintingItemMapByPrintRateForUnits(
        this.tempInvoice.printingItems
      ).forEach((value, key) => {
        this.previousInvoicePrintingItems.push(
          new CombinedItem().setPrintingMountingItem(
            value.type,
            key,
            value,
            printHSNCode,
            firstPrintingItem ? 0 : null
          )
        );
        if (firstPrintingItem) {
          firstPrintingItem = false;
        }
      });
    } else {
      let firstPrintingItem = true;
      this.tempInvoice.printingItems.forEach((item, index) => {
        if (item.amount > 0) {
          // this.previousInvoicePrintingItems.push(new CombinedItem().setAdditionalItem(item, "Printing"));
          this.previousInvoicePrintingItems.push(
            new CombinedItem().setAdditionalItem(
              item,
              "Printing",
              null,
              null,
              null,
              null,
              printHSNCode,
              firstPrintingItem ? 0 : index
            )
          );
          if (item.childInventories.length && this._showChildren) {
            item.childInventories.forEach((child) => {
              this.previousInvoicePrintingItems.push(
                new CombinedItem().setAdditionalItem(
                  child,
                  "Printing",
                  item.lightType,
                  item.printingPrice,
                  null,
                  true
                )
              );
            });
          }
          if (firstPrintingItem) {
            firstPrintingItem = false;
          }
        }
      });

      if (this.previousInvoicePrintingItems.length) {
        this.previousInvoicePrintingItems.forEach((item) => {
          if (
            !this.selectedChargeType.has(item.mediaType) &&
            item.printingPrice
          ) {
            item.printingPrice.perUnit
              ? this.selectedChargeType.set(
                  item.mediaType,
                  ChargeTypeEnum["PER_UNIT"]
                )
              : this.selectedChargeType.set(
                  item.mediaType,
                  ChargeTypeEnum["PER_SQFT"]
                );
          }
        });
      }
    }
  }

  setPreviousInvoiceMountingItems() {
    this.previousInvoiceMountingItems = [];
    const mountHSNCode = this.getMountHSNCode();

    if (this.grouped) {
      // this.getMountingItemMapByPricePerSqft(this.tempInvoice.mountingItems).forEach((value, key) => {
      //     this.previousInvoiceMountingItems.push(new CombinedItem().setPrintingMountingItem('Mounting', key, value));
      // });
      let mountFirstItem = true;

      this.getMountingItemMapByPricePerSqft(
        this.tempInvoice.mountingItems
      ).forEach((value, key) => {
        this.previousInvoiceMountingItems.push(
          new CombinedItem().setPrintingMountingItem(
            value.type,
            key,
            value,
            mountHSNCode,
            mountFirstItem ? 0 : null
          )
        );
        if (mountFirstItem) {
          mountFirstItem = false;
        }
      });
      this.getMountingItemMapByPerUnit(this.tempInvoice.mountingItems).forEach(
        (value, key) => {
          this.previousInvoiceMountingItems.push(
            new CombinedItem().setPrintingMountingItem(
              value.type,
              key,
              value,
              mountHSNCode,
              mountFirstItem ? 0 : null
            )
          );
          if (mountFirstItem) {
            mountFirstItem = false;
          }
        }
      );
    } else {
      let mountFirstItem = true;
      this.tempInvoice.mountingItems.forEach((item, index) => {
        if (item.amount > 0) {
          // this.previousInvoiceMountingItems.push(new CombinedItem().setAdditionalItem(item, "Mounting"));
          this.previousInvoiceMountingItems.push(
            new CombinedItem().setAdditionalItem(
              item,
              "Mounting",
              null,
              null,
              null,
              null,
              mountHSNCode,
              mountFirstItem ? 0 : index
            )
          );
          if (item.childInventories.length && this._showChildren) {
            item.childInventories.forEach((child) => {
              this.previousInvoiceMountingItems.push(
                new CombinedItem().setAdditionalItem(
                  child,
                  "Mounting",
                  item.lightType,
                  null,
                  item.mountingPrice
                )
              );
            });
          }
          if (mountFirstItem) {
            mountFirstItem = false;
          }
        }
      });

      if (this.previousInvoiceMountingItems.length) {
        this.previousInvoiceMountingItems.forEach((item) => {
          if (
            !this.selectedChargeType.has(item.mediaType) &&
            item.mountingPrice
          ) {
            item.mountingPrice.pricePerUnit
              ? this.selectedChargeType.set(
                  item.mediaType,
                  ChargeTypeEnum["PER_UNIT"]
                )
              : this.selectedChargeType.set(
                  item.mediaType,
                  ChargeTypeEnum["PER_SQFT"]
                );
          }
        });
      }
    }
  }

  sortPreviousInvoiceItems() {
    this.sortPreviousInvoiceRentItems();
    this.sortPreviousInvoicePrintingItems();
    this.sortPreviousInvoiceMountingItems();
  }

  sortPreviousInvoiceRentItems() {
    this.previousInvoiceRentItems = utils.sortArray(
      this.previousInvoiceRentItems,
      "city"
    );
    const rentHSNCode = this.getRentHSNCode();
    this.previousInvoiceRentItems.forEach((item, index) => {
      index === 0 ? (item.hsnCode = rentHSNCode) : (item.hsnCode = null);
    });
  }

  sortPreviousInvoicePrintingItems() {
    this.previousInvoicePrintingItems = utils.sortArray(
      this.previousInvoicePrintingItems,
      "city"
    );
    const printHSNCode = this.getPrintHSNCode();
    this.previousInvoicePrintingItems.forEach((item, index) => {
      index === 0 ? (item.hsnCode = printHSNCode) : (item.hsnCode = null);
    });
  }

  sortPreviousInvoiceMountingItems() {
    this.previousInvoiceMountingItems = utils.sortArray(
      this.previousInvoiceMountingItems,
      "city"
    );
    const mountHSNCode = this.getMountHSNCode();
    this.previousInvoiceMountingItems.forEach((item, index) => {
      index === 0 ? (item.hsnCode = mountHSNCode) : (item.hsnCode = null);
    });
  }

  createComparisionItems() {
    switch (InvoiceFormatEnum[this._invoice.invoiceFormat]) {
      case "As Per Ro inclusive of Printing/Mounting": {
        const invoiceRoItems = this.items.filter(
          (item) => item !== undefined && item !== null && item.type === "RO"
        );

        if (
          InvoiceFormatEnum[this.tempInvoice.invoiceFormat] ===
          InvoiceFormatEnum["AS_PER_RO_INCLUSIVE"]
        ) {
          this.bothPreviousInvoiceRoItems = this.previousItems.filter(
            (item) => item !== undefined && item !== null && item.type === "RO"
          );
          invoiceRoItems.forEach((item) => (item.statusType = "both"));
          this.comparisionItems = [...invoiceRoItems];
        } else {
          invoiceRoItems.forEach((item) => (item.statusType = "new"));
          const previousInvoiceItems = this.previousItems.filter(
            (item) => item !== undefined && item !== null && item.type !== "RO"
          );
          previousInvoiceItems.forEach((item) => (item.statusType = "delete"));

          this.comparisionItems = [...invoiceRoItems, ...previousInvoiceItems];
        }
        break;
      }
      case "As Per Ro with separate Printing/Mounting": {
        const invoiceRoRentItems = this.items.filter(
          (item) =>
            item !== undefined && item !== null && item.type === "RO_RENT"
        );
        const invoiceRoPrintMountItems = this.items.filter(
          (item) =>
            item !== undefined &&
            item !== null &&
            item.type === "RO_PRINT_MOUNT"
        );

        if (
          InvoiceFormatEnum[this.tempInvoice.invoiceFormat] ===
          InvoiceFormatEnum["AS_PER_RO_EXCLUSIVE"]
        ) {
          this.bothPreviousInvoiceRoRentItems = this.previousItems.filter(
            (item) =>
              item !== undefined && item !== null && item.type === "RO_RENT"
          );
          this.bothPreviousInvoiceRoPrintMountItems = this.previousItems.filter(
            (item) =>
              item !== undefined &&
              item !== null &&
              item.type === "RO_PRINT_MOUNT"
          );

          invoiceRoRentItems.forEach((item) => (item.statusType = "both"));
          invoiceRoPrintMountItems.forEach(
            (item) => (item.statusType = "both")
          );
          this.comparisionItems = [
            ...invoiceRoRentItems,
            ...invoiceRoPrintMountItems,
          ];
        } else {
          invoiceRoRentItems.forEach((item) => (item.statusType = "new"));
          invoiceRoPrintMountItems.forEach((item) => (item.statusType = "new"));

          const previousInvoiceRentItems = this.previousItems.filter(
            (item) =>
              item !== undefined &&
              item !== null &&
              (item.type === "ANNEXURE" ||
                item.type === "RO" ||
                item.type === "Rent")
          );
          previousInvoiceRentItems.forEach(
            (item) => (item.statusType = "delete")
          );

          const previousInvoicePrintMountItems = this.previousItems.filter(
            (item) =>
              item !== undefined &&
              item !== null &&
              (item.type === "Printing" ||
                item.type === "Mounting" ||
                item.type === "PRINTING_CHARGES" ||
                item.type === "MOUNTING_CHARGES")
          );
          previousInvoicePrintMountItems.forEach(
            (item) => (item.statusType = "delete")
          );

          this.comparisionItems = [
            ...previousInvoiceRentItems,
            ...invoiceRoRentItems,
            ...previousInvoicePrintMountItems,
            ...invoiceRoPrintMountItems,
          ];
        }
        break;
      }
      case "As Per Annexure": {
        const invoiceAnnexureRentItems = this.items.filter(
          (item) =>
            item !== undefined && item !== null && item.type === "ANNEXURE"
        );
        const invoiceAnnexurePrintingItems = this.items.filter(
          (item) =>
            item !== undefined &&
            item !== null &&
            item.type === "PRINTING_CHARGES"
        );
        const invoiceAnnexureMountingItems = this.items.filter(
          (item) =>
            item !== undefined &&
            item !== null &&
            item.type === "MOUNTING_CHARGES"
        );

        if (
          InvoiceFormatEnum[this.tempInvoice.invoiceFormat] ===
          InvoiceFormatEnum["WITH_ATTACHED_ANNEXURE"]
        ) {
          this.bothPreviousInvoiceAnnexureRentItems = this.previousItems.filter(
            (item) =>
              item !== undefined && item !== null && item.type === "ANNEXURE"
          );

          this.bothPreviousInvoiceAnnexurePrintingItems =
            this.previousItems.filter(
              (item) =>
                item !== undefined &&
                item !== null &&
                item.type === "PRINTING_CHARGES"
            );

          this.bothPreviousInvoiceAnnexureMountingItems =
            this.previousItems.filter(
              (item) =>
                item !== undefined &&
                item !== null &&
                item.type === "MOUNTING_CHARGES"
            );

          invoiceAnnexureRentItems.forEach(
            (item) => (item.statusType = "both")
          );
          invoiceAnnexurePrintingItems.forEach(
            (item) => (item.statusType = "both")
          );
          invoiceAnnexureMountingItems.forEach(
            (item) => (item.statusType = "both")
          );

          this.comparisionItems = [
            ...invoiceAnnexureRentItems,
            ...invoiceAnnexurePrintingItems,
            ...invoiceAnnexureMountingItems,
          ];
        } else {
          invoiceAnnexureRentItems.forEach((item) => (item.statusType = "new"));
          invoiceAnnexurePrintingItems.forEach(
            (item) => (item.statusType = "new")
          );
          invoiceAnnexureMountingItems.forEach(
            (item) => (item.statusType = "new")
          );

          const previousInvoiceAnnexureRentItems = this.previousItems.filter(
            (item) =>
              item !== undefined &&
              item !== null &&
              (item.type === "RO" ||
                item.type === "RO_RENT" ||
                item.type === "Rent")
          );
          previousInvoiceAnnexureRentItems.forEach(
            (item) => (item.statusType = "delete")
          );

          const previousInvoiceAnnexurePrintingItems =
            this.previousItems.filter(
              (item) =>
                item !== undefined &&
                item !== null &&
                (item.type === "RO_PRINT_MOUNT" || item.type === "Printing")
            );
          previousInvoiceAnnexurePrintingItems.forEach(
            (item) => (item.statusType = "delete")
          );

          const previousInvoiceAnnexureMountingItems =
            this.previousItems.filter(
              (item) =>
                item !== undefined && item !== null && item.type === "Mounting"
            );
          previousInvoiceAnnexureMountingItems.forEach(
            (item) => (item.statusType = "delete")
          );

          this.comparisionItems = [
            ...previousInvoiceAnnexureRentItems,
            ...invoiceAnnexureRentItems,
            ...previousInvoiceAnnexurePrintingItems,
            ...invoiceAnnexurePrintingItems,
            ...previousInvoiceAnnexureMountingItems,
            ...invoiceAnnexureMountingItems,
          ];
        }

        break;
      }
      default: {
        this.bothInvoiceRentItems = [];
        this.bothPreviousInvoiceRentItems = [];
        this.bothInvoicePrintingItems = [];
        this.bothPreviousInvoicePrintingItems = [];
        this.bothInvoiceMountingItems = [];
        this.bothPreviousInvoiceMountingItems = [];
        const invoiceRentItems: CombinedItem[] = this.items.filter(
          (item) => item !== undefined && item.type === "Rent"
        );
        const invoicePrintingItems: CombinedItem[] = this.items.filter(
          (item) => item !== undefined && item.type === "Printing"
        );
        const invoiceMountingItems: CombinedItem[] = this.items.filter(
          (item) => item !== undefined && item.type === "Mounting"
        );
        const previousInvoiceRentItems: CombinedItem[] =
          this.previousItems.filter(
            (item) => item !== undefined && item.type === "Rent"
          );
        const previousInvoicePrintingItems: CombinedItem[] =
          this.previousItems.filter(
            (item) => item !== undefined && item.type === "Printing"
          );
        const previousInvoiceMountingItems: CombinedItem[] =
          this.previousItems.filter(
            (item) => item !== undefined && item.type === "Mounting"
          );

        const onlyPreviousInvoiceRentItems: CombinedItem[] = [
          ...this.getItemsFromLists(
            previousInvoiceRentItems,
            invoiceRentItems,
            "Rent",
            true
          ),
          ...this.previousItems.filter(
            (item) =>
              item.type === "ANNEXURE" ||
              item.type === "RO" ||
              item.type === "RO_RENT"
          ),
        ];
        onlyPreviousInvoiceRentItems.forEach(
          (item) => (item.statusType = "delete")
        );

        const onlyInvoiceRentItems: CombinedItem[] = this.getItemsFromLists(
          invoiceRentItems,
          previousInvoiceRentItems,
          "Rent",
          true
        );
        onlyInvoiceRentItems.forEach((item) => (item.statusType = "new"));

        this.bothInvoiceRentItems = this.getItemsFromLists(
          invoiceRentItems,
          previousInvoiceRentItems,
          "Rent",
          false
        );
        this.bothPreviousInvoiceRentItems = this.getItemsFromLists(
          previousInvoiceRentItems,
          invoiceRentItems,
          "Rent",
          false
        );
        this.bothInvoiceRentItems.forEach((item) => {
          if (
            this.bothPreviousInvoiceRentItems.some(
              (itm) => item.id === itm.id && item.amount !== itm.amount
            )
          ) {
            item.statusType = "both";
          }
        });

        const onlyPreviousInvoicePrintingItems: CombinedItem[] = [
          ...this.getPrintMountItemsForUngrouped(
            previousInvoicePrintingItems,
            invoicePrintingItems,
            "Printing",
            true
          ),
          ...this.previousItems.filter(
            (item) =>
              item.type === "PRINTING_CHARGES" || item.type === "RO_PRINT_MOUNT"
          ),
        ];
        onlyPreviousInvoicePrintingItems.forEach(
          (item) => (item.statusType = "delete")
        );

        const onlyInvoicePrintingItems: CombinedItem[] =
          this.getPrintMountItemsForUngrouped(
            invoicePrintingItems,
            previousInvoicePrintingItems,
            "Printing",
            true
          );
        onlyInvoicePrintingItems.forEach((item) => (item.statusType = "new"));

        this.bothInvoicePrintingItems = this.grouped
          ? this.getItemsFromLists(
              invoicePrintingItems,
              previousInvoicePrintingItems,
              "Printing",
              false
            )
          : this.getPrintMountItemsForUngrouped(
              invoicePrintingItems,
              previousInvoicePrintingItems,
              "Printing",
              false
            );
        this.bothPreviousInvoicePrintingItems = this.grouped
          ? this.getItemsFromLists(
              previousInvoicePrintingItems,
              invoicePrintingItems,
              "Printing",
              false
            )
          : this.getPrintMountItemsForUngrouped(
              previousInvoicePrintingItems,
              invoicePrintingItems,
              "Printing",
              false
            );
        this.bothInvoicePrintingItems.forEach(
          (item) => (item.statusType = "both")
        );

        const onlyPreviousInvoiceMountingItems: CombinedItem[] = [
          ...this.getPrintMountItemsForUngrouped(
            previousInvoiceMountingItems,
            invoiceMountingItems,
            "Mounting",
            true
          ),
          ...this.previousItems.filter(
            (item) => item.type === "MOUNTING_CHARGES"
          ),
        ];
        onlyPreviousInvoiceMountingItems.forEach(
          (item) => (item.statusType = "delete")
        );

        const onlyInvoiceMountingItems: CombinedItem[] =
          this.getPrintMountItemsForUngrouped(
            invoiceMountingItems,
            previousInvoiceMountingItems,
            "Mounting",
            true
          );
        onlyInvoiceMountingItems.forEach((item) => (item.statusType = "new"));

        this.bothInvoiceMountingItems = this.grouped
          ? this.getItemsFromLists(
              invoiceMountingItems,
              previousInvoiceMountingItems,
              "Mounting",
              false
            )
          : this.getPrintMountItemsForUngrouped(
              invoiceMountingItems,
              previousInvoiceMountingItems,
              "Mounting",
              false
            );
        this.bothPreviousInvoiceMountingItems = this.grouped
          ? this.getItemsFromLists(
              previousInvoiceMountingItems,
              invoiceMountingItems,
              "Mounting",
              false
            )
          : this.getPrintMountItemsForUngrouped(
              previousInvoiceMountingItems,
              invoiceMountingItems,
              "Mounting",
              false
            );
        this.bothInvoiceMountingItems.forEach(
          (item) => (item.statusType = "both")
        );

        this.comparisionItems = [
          ...onlyPreviousInvoiceRentItems,
          ...this.bothInvoiceRentItems,
          ...onlyInvoiceRentItems,
          ...onlyPreviousInvoicePrintingItems,
          ...this.bothInvoicePrintingItems,
          ...onlyInvoicePrintingItems,
          ...onlyPreviousInvoiceMountingItems,
          ...this.bothInvoiceMountingItems,
          ...onlyInvoiceMountingItems,
        ];
      }
    }
    this.comparisionItems = JSON.parse(JSON.stringify(this.comparisionItems));
    if (this.invoice.status && this.gridConfig.dataLoadFunction !== undefined) {
      this.invoiceSummaryGridRef.refresh();
    }
  }

  getPreviousInvoiceRentItem(row) {
    const index = this.bothPreviousInvoiceRentItems.findIndex(
      (item) => item.customId === row.customId
    );
    return this.bothPreviousInvoiceRentItems[index];
  }

  getPreviousInvoicePrintingItem(row) {
    const index = this.grouped
      ? this.bothPreviousInvoicePrintingItems.findIndex(
          (item) =>
            item.printRate === row.printRate &&
            item.squareFeet === row.squareFeet
        )
      : this.bothPreviousInvoicePrintingItems.findIndex(
          (item) => item.customId === row.customId
        );
    return this.bothPreviousInvoicePrintingItems[index];
  }

  getPreviousInvoiceMountingItem(row) {
    const index = this.grouped
      ? this.bothPreviousInvoiceMountingItems.findIndex(
          (item) =>
            item.mountRate === row.mountRate &&
            item.squareFeet === row.squareFeet
        )
      : this.bothPreviousInvoiceMountingItems.findIndex(
          (item) => item.customId === row.customId
        );
    return this.bothPreviousInvoiceMountingItems[index];
  }

  getPreviousInvoiceRoRentPrintMountItem(row) {
    if (row && row.type) {
      switch (row.type) {
        case "RO": {
          return this.bothPreviousInvoiceRoItems[0];
        }
        case "RO_RENT": {
          return this.bothPreviousInvoiceRoRentItems[0];
        }
        case "RO_PRINT_MOUNT": {
          return this.bothPreviousInvoiceRoPrintMountItems[0];
        }
      }
    } else {
      return;
    }
  }

  getPreviousAnnexureRentPrintMountItem(row) {
    if (row && row.type) {
      switch (row.type) {
        case "ANNEXURE":
          return this.bothPreviousInvoiceAnnexureRentItems[0];
        case "PRINTING_CHARGES":
          return this.bothPreviousInvoiceAnnexurePrintingItems[0];
        case "MOUNTING_CHARGES":
          return this.bothPreviousInvoiceAnnexureMountingItems[0];
      }
    } else {
      return;
    }
  }

  getItemsFromLists(fromList, comparisionList, type, unique) {
    const list: CombinedItem[] = [];
    fromList.filter((item) => {
      let found = false;
      let index = -1;
      comparisionList.forEach((itm, ind) => {
        switch (type) {
          case "Rent":
          case "ANNEXURE": {
            // if(unique){
            if (item.customId === itm.customId) {
              found = true;
              index = ind;
            }
            // }
            // else{
            //     if(item.customId === itm.customId && item.amount !== itm.amount){
            //         found = true;
            //         index
            //     }
            // }

            break;
          }
          case "Printing": {
            if (
              item.printRate === itm.printRate &&
              item.squareFeet === itm.squareFeet
            ) {
              found = true;
              index = ind;
            }
            break;
          }
          case "Mounting": {
            if (
              item.mountRate === itm.mountRate &&
              item.squareFeet === itm.squareFeet
            ) {
              found = true;
              index = ind;
            }
            break;
          }
        }
      });
      if (unique) {
        if (!found) {
          list.push(item);
        }
      } else {
        if (found) {
          list.push(item);
          // list.push(comparisionList[index]);
        }
      }
    });
    return list;
  }

  getPrintMountItemsForUngrouped(fromList, comparisionList, type, unique) {
    const list: CombinedItem[] = [];
    fromList.filter((item) => {
      let found = false;
      let index = -1;
      comparisionList.forEach((itm, ind) => {
        switch (type) {
          case "Printing": {
            // if (item.customId === itm.customId) {
            if (item.id === itm.id) {
              found = true;
              index = ind;
            }
            break;
          }
          case "Mounting":
          case "PRINTING_CHARGES":
          case "MOUNTING_CHARGES": {
            // if (item.customId === itm.customId) {
            if (item.id === itm.id) {
              found = true;
              index = ind;
            }
            break;
          }
        }
      });
      if (unique) {
        if (!found) {
          list.push(item);
        }
      } else {
        if (found) {
          list.push(item);
          // list.push(comparisionList[index]);
        }
      }
    });
    return list;
  }

  calculateTax(value, taxValue) {
    return Number(Math.round((value * taxValue) / 100).toFixed(2));
  }

  onSort(event) {
    this.sorting = true;
    this.items = this.invoiceSummaryGridRef.getDataTableInstance()["_value"];
    if (
      InvoiceFormatEnum[this.invoiceFormat] !== "As Per Annexure" &&
      InvoiceFormatEnum[this.invoiceFormat] !==
        "As Per Ro inclusive of Printing/Mounting" &&
      InvoiceFormatEnum[this.invoiceFormat] !==
        "As Per Ro with separate Printing/Mounting"
    ) {
      this.sortingItemsBasedOnItsItemType();
    }

    this.emitRowsToRearrange();
    this.invoiceSummaryGridRef.refresh();
  }

  getItemIds(isInvFormatFullDetail?) {
    this.isInvFormatFullDetail = isInvFormatFullDetail;
    this.setItems();
    return this.items.map((item) => item.id);
  }

  getOnlyGroupIds(isInvFormatFullDetail?) {
    this.isInvFormatFullDetail = isInvFormatFullDetail;
    this.setItems();
    return this.onlyGroupItemsId;
  }

  ngOnDestroy() {
    this.subscription.forEach((s) => {
      s.unsubscribe();
    });
  }

  /* Getting decimal point in Number datatype
   *  @param  {} source
   */
  getDecimalNum(target, decimal) {
    // return Number(target.toFixed(decimal));

    return Number(Math.fround(target).toFixed(decimal));
  }

  getRoundedOffValue(value) {
    return utils.getIntegerRoundedOffValue(value);
  }

  getPrintingItemMapByPrintRate(items) {
    const printingItemMap = new Map<Number, any>();

    items.forEach((item) => {
      if (
        item.printingPrice.flNlPrice > 0 ||
        item.printingPrice.blPrice > 0 ||
        (item.printingPrice.variousPrice > 0 && !item.printingPrice.perUnit)
      ) {
        this.setPrintingMapForSqft(item, printingItemMap);
      }
    });
    return printingItemMap;
  }

  getPrintingItemMapByPrintRateForUnits(items) {
    const printingItemMapForUnits = new Map<Number, any>();
    items.forEach((item) => {
      if (
        item.printingPrice.pricePerUnitFl > 0 ||
        item.printingPrice.pricePerUnitBl > 0 ||
        (item.printingPrice.variousPrice > 0 && item.printingPrice.perUnit)
      ) {
        this.setPrintingMapForUnit(item, printingItemMapForUnits);
      }
    });

    return printingItemMapForUnits;
  }

  /**
   * @description setting printing map for inventories which have charge type as per sqft
   * @author Raveena Nathani
   * @date 2020-03-04
   * @param {*} item
   * @param {*} printingItemMap
   * @memberof InvoiceSummaryGridComponent
   */
  setPrintingMapForSqft(item, printingItemMap) {
    // key3 > 0 ? key3 : key1 + key2

    let variousPrintPrice = 0;
    const key1 = item.printingPrice.flNlPrice;
    const key2 = item.printingPrice.blPrice;
    if (item.childInventories.length) {
      item.childInventories.forEach((child) => {
        variousPrintPrice +=
          child.lightType === "F_L" || child.lightType === "N_L"
            ? item.printingPrice.flNlPrice
            : item.printingPrice.blPrice;
      });
    }
    const key3 = item.printingPrice.variousPrice
      ? item.printingPrice.variousPrice
      : variousPrintPrice;
    const obj: any = {};
    if (
      HoardingLightEnum[item.lightType] === HoardingLightEnum["F_L"] ||
      (HoardingLightEnum[item.lightType] === HoardingLightEnum["N_L"] &&
        printingItemMap.has(key1)) ||
      (HoardingLightEnum[item.lightType] === HoardingLightEnum["B_L"] &&
        printingItemMap.has(key2)) ||
      (HoardingLightEnum[item.lightType] === HoardingLightEnum["VARIOUS"] &&
        printingItemMap.has(key3))
    ) {
      obj.squareFeet = Number(obj.squareFeet >= 0)
        ? Number(obj.squareFeet) + Number(item.squareFeet)
        : 0 + Number(item.squareFeet);
      obj.amount = Number(obj.amount >= 0)
        ? Number(obj.amount) + Number(item.amount)
        : 0 + Number(item.amount);
      const oldObj =
        HoardingLightEnum[item.lightType] === HoardingLightEnum["F_L"] ||
        HoardingLightEnum[item.lightType] === HoardingLightEnum["N_L"]
          ? printingItemMap.get(key1)
          : HoardingLightEnum[item.lightType] === HoardingLightEnum["B_L"]
          ? printingItemMap.get(key2)
          : printingItemMap.get(key3);
      if (oldObj) {
        obj.amount = Number(obj.amount) + Number(oldObj.amount);
        obj.squareFeet = Number(obj.squareFeet) + Number(oldObj.squareFeet);
      }
      HoardingLightEnum[item.lightType] === HoardingLightEnum["F_L"] ||
      HoardingLightEnum[item.lightType] === HoardingLightEnum["N_L"]
        ? printingItemMap.set(key1, obj)
        : HoardingLightEnum[item.lightType] === HoardingLightEnum["B_L"]
        ? printingItemMap.set(key2, obj)
        : printingItemMap.set(key3, obj);
    } else {
      obj.squareFeet = Number(obj.squareFeet >= 0)
        ? Number(obj.squareFeet) + Number(item.squareFeet)
        : 0 + Number(item.squareFeet);
      obj.amount = Number(obj.amount >= 0)
        ? Number(obj.amount) + Number(item.amount)
        : 0 + Number(item.amount);
      HoardingLightEnum[item.lightType] === HoardingLightEnum["F_L"] ||
      HoardingLightEnum[item.lightType] === HoardingLightEnum["N_L"]
        ? printingItemMap.set(key1, obj)
        : HoardingLightEnum[item.lightType] === HoardingLightEnum["B_L"]
        ? printingItemMap.set(key2, obj)
        : printingItemMap.set(key3, obj);
    }
    obj.type = "Printing";
    obj.mediaType = item.mediaType;
  }

  /**
   * @description setting printing map for inventories which have charge type as per unit
   * @author Raveena Nathani
   * @date 2020-03-04
   * @param {*} item
   * @param {*} printingItemMapForUnits
   * @memberof InvoiceSummaryGridComponent
   */
  setPrintingMapForUnit(item, printingItemMapForUnits) {
    let variousPrintPrice = 0;
    const key1 = item.printingPrice.pricePerUnitFl;
    const key2 = item.printingPrice.pricePerUnitBl;
    if (item.childInventories.length) {
      item.childInventories.forEach((child) => {
        variousPrintPrice +=
          child.lightType === "F_L" || child.lightType === "N_L"
            ? item.printingPrice.pricePerUnitFl
            : item.printingPrice.pricePerUnitBl;
      });
    }
    const key3 = item.printingPrice.variousPrice
      ? item.printingPrice.variousPrice
      : variousPrintPrice;
    const obj: any = {};
    let totalUnits = 0;
    if (item.childInventories && item.childInventories.length > 0) {
      item.childInventories.forEach((child) => {
        if (
          InventoryTypeEnum[child.inventoryType] ===
          InventoryTypeEnum["DOUBLE_SIDE"]
        ) {
          totalUnits += Number(child.quantity) * 2;
        } else {
          totalUnits += Number(child.quantity);
        }
      });
    }

    totalUnits =
      totalUnits > 0
        ? this.getDecimalNum(totalUnits * item.quantity, 2)
        : InventoryTypeEnum[item.inventoryType] ===
          InventoryTypeEnum["DOUBLE_SIDE"]
        ? this.getDecimalNum(item.quantity * 2, 2)
        : this.getDecimalNum(item.quantity, 2);
    if (
      HoardingLightEnum[item.lightType] === HoardingLightEnum["F_L"] ||
      (HoardingLightEnum[item.lightType] === HoardingLightEnum["N_L"] &&
        printingItemMapForUnits.has(key1)) ||
      (HoardingLightEnum[item.lightType] === HoardingLightEnum["B_L"] &&
        printingItemMapForUnits.has(key2)) ||
      (HoardingLightEnum[item.lightType] === HoardingLightEnum["VARIOUS"] &&
        printingItemMapForUnits.has(key3))
    ) {
      obj.totalUnits = Number(obj.totalUnits >= 0)
        ? Number(obj.totalUnits) + Number(totalUnits)
        : 0 + Number(totalUnits);
      obj.amount = Number(obj.amount >= 0)
        ? Number(obj.amount) + Number(item.amount)
        : 0 + Number(item.amount);
      const oldObj =
        HoardingLightEnum[item.lightType] === HoardingLightEnum["F_L"] ||
        HoardingLightEnum[item.lightType] === HoardingLightEnum["N_L"]
          ? printingItemMapForUnits.get(key1)
          : HoardingLightEnum[item.lightType] === HoardingLightEnum["B_L"]
          ? printingItemMapForUnits.get(key2)
          : printingItemMapForUnits.get(key3);
      if (oldObj) {
        obj.amount = Number(obj.amount) + Number(oldObj.amount);
        obj.totalUnits = Number(obj.totalUnits) + Number(oldObj.totalUnits);
      }
      HoardingLightEnum[item.lightType] === HoardingLightEnum["F_L"] ||
      HoardingLightEnum[item.lightType] === HoardingLightEnum["N_L"]
        ? printingItemMapForUnits.set(key1, obj)
        : HoardingLightEnum[item.lightType] === HoardingLightEnum["B_L"]
        ? printingItemMapForUnits.set(key2, obj)
        : printingItemMapForUnits.set(key3, obj);
    } else {
      obj.totalUnits = Number(obj.totalUnits >= 0)
        ? Number(obj.totalUnits) + Number(totalUnits)
        : 0 + Number(totalUnits);
      obj.amount = Number(obj.amount >= 0)
        ? Number(obj.amount) + Number(item.amount)
        : 0 + Number(item.amount);
      HoardingLightEnum[item.lightType] === HoardingLightEnum["F_L"] ||
      HoardingLightEnum[item.lightType] === HoardingLightEnum["N_L"]
        ? printingItemMapForUnits.set(key1, obj)
        : HoardingLightEnum[item.lightType] === HoardingLightEnum["B_L"]
        ? printingItemMapForUnits.set(key2, obj)
        : printingItemMapForUnits.set(key3, obj);
    }
    obj.type = "UnitPrinting";
    obj.mediaType = item.mediaType;
  }

  getPrintingItemMapByAmount(items) {
    const printingItemMap = new Map<number, number>();
    items.forEach((item) => {
      if (item.amount > 0) {
        printingItemMap.set(item.amount, item.squareFeet);
      } else {
        // will not haapen
        // printingItemMap.set(0, item.squareFeet);
        // do nothing
      }
    });
    return printingItemMap;
  }

  getMountingItemMapByPricePerSqft(items) {
    const mountingItemMap = new Map<Number, any>();
    items.forEach((item) => {
      if (
        item.mountingPrice.pricePerSqFt > 0 ||
        item.mountingPrice.perSqftForVarious > 0
      ) {
        const key1 = item.mountingPrice.pricePerSqFt;
        const key2 = item.mountingPrice.perSqftForVarious;
        const obj: any = {};
        if (
          mountingItemMap.has(key1 > 0 ? key1 : key2) ||
          mountingItemMap.has(key2 > 0 ? key2 : key1)
        ) {
          obj.squareFeet = Number(obj.squareFeet >= 0)
            ? Number(obj.squareFeet) + Number(item.squareFeet)
            : 0 + Number(item.squareFeet);
          obj.amount = Number(obj.amount >= 0)
            ? Number(obj.amount) + Number(item.amount)
            : 0 + Number(item.amount);
          const oldObj = mountingItemMap.get(key1 > 0 ? key1 : key2);
          obj.squareFeet = Number(obj.squareFeet) + Number(oldObj.squareFeet);
          obj.amount = Number(obj.amount) + Number(oldObj.amount);
          mountingItemMap.set(key1 > 0 ? key1 : key2, obj);
        } else {
          obj.squareFeet = Number(obj.squareFeet >= 0)
            ? Number(obj.squareFeet) + Number(item.squareFeet)
            : 0 + Number(item.squareFeet);
          obj.amount = Number(obj.amount >= 0)
            ? Number(obj.amount) + Number(item.amount)
            : 0 + Number(item.amount);
          mountingItemMap.set(key1 > 0 ? key1 : key2, obj);
        }

        obj.type = "Mounting";
        obj.mediaType = item.mediaType;
      }
    });
    return mountingItemMap;
  }

  getMountingItemMapByPerUnit(items) {
    const unitmountingMap = new Map<number, any>();

    items.forEach((item) => {
      let totalUnits = 0;
      if (item.childInventories && item.childInventories.length > 0) {
        item.childInventories.forEach((child) => {
          if (
            InventoryTypeEnum[child.inventoryType] ===
            InventoryTypeEnum["DOUBLE_SIDE"]
          ) {
            totalUnits += Number(child.quantity) * 2;
          } else {
            totalUnits += Number(child.quantity);
          }
        });
      }

      totalUnits =
        totalUnits > 0
          ? this.getDecimalNum(totalUnits * item.quantity, 2)
          : InventoryTypeEnum[item.inventoryType] ===
            InventoryTypeEnum["DOUBLE_SIDE"]
          ? this.getDecimalNum(item.quantity * 2, 2)
          : this.getDecimalNum(item.quantity, 2);
      if (item.mountingPrice.pricePerUnit > 0) {
        const key = item.mountingPrice.pricePerUnit;
        const obj: any = {};
        if (unitmountingMap.has(key)) {
          obj.totalUnits = Number(obj.totalUnits >= 0)
            ? Number(obj.totalUnits) + Number(totalUnits)
            : 0 + Number(totalUnits);
          obj.amount = Number(obj.amount >= 0)
            ? Number(obj.amount) + Number(item.amount)
            : 0 + Number(item.amount);
          const oldObj = unitmountingMap.get(key);
          obj.totalUnits = Number(obj.totalUnits) + Number(oldObj.totalUnits);
          obj.amount = Number(obj.amount) + Number(oldObj.amount);
          unitmountingMap.set(key, obj);
        } else {
          obj.totalUnits = Number(obj.totalUnits >= 0)
            ? Number(obj.totalUnits) + Number(totalUnits)
            : 0 + Number(totalUnits);
          obj.amount = Number(obj.amount >= 0)
            ? Number(obj.amount) + Number(item.amount)
            : 0 + Number(item.amount);
          unitmountingMap.set(key, obj);
        }
        obj.type = "UnitMounting";
        obj.mediaType = item.mediaType;
      }
    });

    return unitmountingMap;
  }

  getMountingItemMapByAmount(items) {
    const mountingItemMap = new Map<number, number>();
    items.forEach((item) => {
      if (item.amount > 0) {
        mountingItemMap.set(item.amount, item.squareFeet);
      } else {
        // will not haapen
        // mountingItemMap.set(0, item.squareFeet);
        // do nothing
      }
    });
    return mountingItemMap;
  }

  getFormattedDate(date) {
    return DateUtil.dategridFormatter(date);
  }

  getParseValueToDecimalTwoPlaces(value) {
    return utils.parseValueToDecimalTwoPlaces(value);
  }

  getFormattedCurrency(value) {
    return this.currencyPipe.transform(
      utils.parseValueToDecimalTwoPlaces(value)
    );
  }

  /**
   * @description emits the event for rows to rearrange
   * @author Pulkit Bansal
   * @date 2019-10-06
   * @memberof InvoiceSummaryGridComponent
   */
  emitRowsToRearrange() {
    this.eEmitRowsToRearrange.emit(this.items);
    this.defaultSortInProgress = false;
  }

  /**
   * @description to get the current row order of grid
   * @author Pulkit Bansal
   * @date 2019-10-09
   * @returns
   * @memberof InvoiceSummaryGridComponent
   */
  getCurrentInvoiceGridOrder() {
    return this.invoiceSummaryGridRef.getDataTableInstance()["_value"];
  }

  getInvoiceGridOrder() {
    const gridItems = [];
    this.items.forEach((item) => {
      if (item.amount) {
        gridItems.push(item);
      }
    });

    return gridItems;
  }

  getInvoiceGridItemIds() {
    const ids = [];
    this.items.forEach((item) => {
      if (item.amount) {
        ids.push(item.id);
      }
    });

    return ids;
  }

  /**
   * @description to update the order of rows
   * also called from invoice preview component
   * @author Pulkit Bansal
   * @date 2019-10-09
   * @param {string[]} rowsOrder
   * @param {boolean} [calledFromThisComponent=false]
   * @memberof InvoiceSummaryGridComponent
   */
  updateItemsOrder(rowsOrder: string[], calledFromThisComponent = false) {
    const updatedItems: CombinedItem[] = [];
    let notFound = false;
    this.invoiceRowsOrder = [];
    this.invoiceRowsOrder = rowsOrder;
    this.sorting = false;

    if (!this._showChildren) {
      rowsOrder.forEach((id) => {
        const index = this.items.findIndex((item) => item.id === id);
        if (index !== -1) {
          updatedItems.push(this.items[index]);
        } else {
          notFound = true;
        }
      });
    } else {
      // let notFound = false;
      rowsOrder.forEach((id) => {
        const index = this.items.findIndex((item) => item.id === id);
        if (index !== -1) {
          updatedItems.push(this.items[index]);
          for (let i = index + 1; i < this.items.length; i++) {
            if (!this.items[i].amount) {
              updatedItems.push(this.items[i]);
            } else {
              break;
            }
          }
        } else {
          notFound = true;
        }
      });
    }
    if (!notFound) {
      this.items = _.cloneDeep(updatedItems);
    }
    if (!calledFromThisComponent) {
      if (this.gridConfig.dataLoadFunction !== undefined) {
        if (
          this.hsnCodeTemplatePresent &&
          (this._invoice.rowsOrder.length > 0 ||
            this.invoiceRowsOrder.length > 0) &&
          InvoiceFormatEnum[this.invoiceFormat] !== "As Per Annexure" &&
          InvoiceFormatEnum[this.invoiceFormat] !==
            "As Per Ro inclusive of Printing/Mounting" &&
          InvoiceFormatEnum[this.invoiceFormat] !==
            "As Per Ro with separate Printing/Mounting"
        ) {
          this.setItemsAfterRowRearrangement();
        }
        this.invoiceSummaryGridRef.refresh();
      }
      if (this.invoice.status) {
        // invoice is edited, not generated
        this.setPreviousInvoiceItems();
      }
    }
  }

  /**
   * @description to update the order of rows for previous items
   * @author Pulkit Bansal
   * @date 2019-10-31
   * @param {string[]} rowsOrder
   * @memberof InvoiceSummaryGridComponent
   */
  updatePreviousItemsOrder(rowsOrder: string[]) {
    const updatedItems: CombinedItem[] = [];
    let notFound = false;
    rowsOrder.forEach((id) => {
      const index = this.previousItems.findIndex((item) => item.id === id);
      if (index !== -1) {
        updatedItems.push(this.previousItems[index]);
      } else {
        notFound = true;
      }
    });
    if (!notFound && rowsOrder.length === this.previousItems.length) {
      this.previousItems = _.cloneDeep(updatedItems);
    }
  }

  /**
   * @description to validate current rows order wrt grouped and ungrouped
   * @returns {boolean}
   * @memberof InvoiceSummaryGridComponent
   */
  validateInvioceRowsOrder(): boolean {
    if (
      InvoiceFormatEnum[this.invoiceFormat] ===
      "As Per Ro inclusive of Printing/Mounting"
    ) {
      if (this.isInvoiceRowsOrderPresent()) {
        return (
          !this.checkRowsOrderForNullUndefined() &&
          this.rowsOrderContainsCustomIdForInvoiceFormats("As Per Ro")
        );
      } else {
        return false;
      }
    } else if (
      InvoiceFormatEnum[this.invoiceFormat] ===
      "As Per Ro with separate Printing/Mounting"
    ) {
      if (this.isInvoiceRowsOrderPresent()) {
        return (
          !this.checkRowsOrderForNullUndefined() &&
          this.rowsOrderContainsCustomIdForInvoiceFormats("As Per Ro - Rent") &&
          this.rowsOrderContainsCustomIdForInvoiceFormats(
            "As Per Ro - Printing/Mounting"
          )
        );
      } else {
        return false;
      }
    } else if (InvoiceFormatEnum[this.invoiceFormat] === "As Per Annexure") {
      if (this.isInvoiceRowsOrderPresent()) {
        return (
          !this.checkRowsOrderForNullUndefined() &&
          this.rowsOrderContainsCustomIdForInvoiceFormats(
            "As Per Attached Annexure"
          ) &&
          this.rowsOrderContainsCustomIdForInvoiceFormats("Printing Charges") &&
          this.rowsOrderContainsCustomIdForInvoiceFormats("Mounting Charges")
        );
      }
      return false;
    } else {
      // if invoice contains rowOrder
      if (this.isInvoiceRowsOrderPresent()) {
        // if the invoice is grouped
        if (this.grouped) {
          // if invoice contains print or mount items
          if (this.containsPrintOrMountItems()) {
            return (
              !this.checkRowsOrderForNullUndefined() &&
              this.rowsOrderContainsCustomIdForGroupedItems()
            );
          } else {
            return (
              !this.checkRowsOrderForNullUndefined() &&
              !this.rowsOrderContainsCustomIdForGroupedItems()
            );
          }
        } else {
          // invoice is not grouped
          return (
            !this.checkRowsOrderForNullUndefined() &&
            !this.rowsOrderContainsCustomIdForGroupedItems() &&
            !this.rowsOrderContainsCustomIdForInvoiceFormats("As Per Ro") &&
            !this.rowsOrderContainsCustomIdForInvoiceFormats(
              "As Per Ro - Rent"
            ) &&
            !this.rowsOrderContainsCustomIdForInvoiceFormats(
              "As Per Ro - Printing/Mounting"
            )
          );
        }
      } else {
        return false;
      }
    }
  }

  /**
   * @description to check if rows order exists in invoice
   * @returns {boolean}
   * @memberof InvoiceSummaryGridComponent
   */
  isInvoiceRowsOrderPresent(): boolean {
    if (this._invoice.rowsOrder && this._invoice.rowsOrder.length) {
      return true;
    } else {
      return false;
    }
  }

  /**
   * @description to check if rows order contains null or undefined
   * @returns {boolean}
   * @memberof InvoiceSummaryGridComponent
   */
  checkRowsOrderForNullUndefined(): boolean {
    if (
      this._invoice.rowsOrder.findIndex(
        (item) => item !== undefined && item !== null
      ) !== -1
    ) {
      return false;
    } else {
      return true;
    }
  }

  /**
   * @description to check if invioce contains print or mount items
   * @returns {boolean}
   * @memberof InvoiceSummaryGridComponent
   */
  containsPrintOrMountItems(): boolean {
    if (
      (this._invoice.printingItems && this._invoice.printingItems.length) ||
      (this._invoice.mountingItems && this._invoice.mountingItems.length)
    ) {
      return true;
    } else {
      return false;
    }
  }

  /**
   * @description to check if the rows order in invoice contains custom ids -> for groupoed print and mount items
   * @returns {boolean}
   * @memberof InvoiceSummaryGridComponent
   */
  rowsOrderContainsCustomIdForGroupedItems(): boolean {
    const rowsOrder = this._invoice.rowsOrder.filter((item) => item !== null);
    if (rowsOrder.findIndex((item) => item.includes("@")) !== -1) {
      return true;
    } else {
      return false;
    }
  }

  /**
   * @description to check if rows order contains custom ids --> for ro types in invoice format enum
   * @param {string} checkValue
   * @returns {boolean}
   * @memberof InvoiceSummaryGridComponent
   */
  rowsOrderContainsCustomIdForInvoiceFormats(checkValue: string): boolean {
    if (
      this._invoice.rowsOrder.findIndex((item) => item === checkValue) !== -1
    ) {
      return true;
    } else {
      return false;
    }
  }

  /**
   * @description sorts in default order --> by city
   * called from invoice preview component
   * @memberof InvoiceSummaryGridComponent
   */
  onDefaultSort() {
    this.defaultSortInProgress = true;
    this.setItems();
  }

  /**
   * @description to get the amount based on invoiceFormat
   * called from the html template
   * @param {CombinedItem} row
   * @returns {number}
   * @memberof InvoiceSummaryGridComponent
   */
  getAmountBasedOnInvoiceFormat(
    row: CombinedItem,
    index: number
  ): number | string {
    switch (InvoiceFormatEnum[this._invoiceFormat]) {
      case "Package Rate inclusive of Printing/Mounting": {
        if (index === 0) {
          return this.getRoundedOffValue(
            this.rentItems
              .map((rentItem) => rentItem.amount)
              .reduce((sum, rentItem) => sum + rentItem, 0) +
              this.printingItems
                .map((printItem) => printItem.amount)
                .reduce((sum, printItem) => sum + printItem, 0) +
              this.mountingItems
                .map((mountItem) => mountItem.amount)
                .reduce((sum, mountItem) => sum + mountItem, 0)
          );
        } else {
          return "-";
        }
        break;
      }
      case "Package Rate with separate Printing/Mounting": {
        // templating is starting from the beginning so reset these values
        if (index === 0) {
          this.firstRentalItem = true;
          this.firstPrintingMountingItem = true;
        } else {
          // do nothing
        }
        switch (row.type) {
          case "Rent": {
            if (this.firstRentalItem) {
              this.firstRentalItem = false;
              return this.getRoundedOffValue(
                this.rentItems
                  .map((rentItem) => rentItem.amount)
                  .reduce((sum, rentItem) => sum + rentItem, 0)
              );
            } else {
              return "-";
            }
            break;
          }
          case "Printing":
          case "Mounting": {
            if (this.firstPrintingMountingItem) {
              this.firstPrintingMountingItem = false;
              return this.getRoundedOffValue(
                this.printingItems
                  .map((printItem) => printItem.amount)
                  .reduce((sum, printItem) => sum + printItem, 0) +
                  this.mountingItems
                    .map((mountItem) => mountItem.amount)
                    .reduce((sum, mountItem) => sum + mountItem, 0)
              );
            } else {
              return "-";
            }
            break;
          }
        }
        break;
      }
      case "Full Detail":
      case "Full Detail(Compress)": {
        const rentItems = this.items.filter((item) => item.id === row.id);
        if (rentItems && rentItems.length) {
          return rentItems[0].amount;
        } else {
          return "-";
        }
      }
      default: {
        const rentItems = this.items.filter((item) => item.id === row.id);
        if (rentItems && rentItems.length) {
          return rentItems[0].amount;
        } else {
          return "-";
        }
        break;
      }
    }
  }

  /**
   * @description to get the previous amount based on invoice format
   * called from the html template
   * @param {CombinedItem} row
   * @param {number} index
   * @returns {(number | string)}
   * @memberof InvoiceSummaryGridComponent
   */
  getPreviousAmountBasedOnInvoiceFormat(
    row: CombinedItem,
    index: number
  ): number | string {
    switch (InvoiceFormatEnum[this._invoiceFormat]) {
      case "Package Rate inclusive of Printing/Mounting": {
        if (index === 0) {
          return this.getRoundedOffValue(
            this.previousInvoiceRentItems
              .map((rentItem) => rentItem.amount)
              .reduce((sum, rentItem) => sum + rentItem, 0) +
              this.printingItems
                .map((printItem) => printItem.amount)
                .reduce((sum, printItem) => sum + printItem, 0) +
              this.mountingItems
                .map((mountItem) => mountItem.amount)
                .reduce((sum, mountItem) => sum + mountItem, 0)
          );
        } else {
          return "-";
        }
      }
      case "Package Rate with separate Printing/Mounting": {
        // templating is starting from the beginning so reset these values
        if (index === 0) {
          this.firstPreviusRentalItem = true;
          this.firstPreviousPrintingMountingItem = true;
        } else {
          // do nothing
        }
        switch (row.type) {
          case "Rent": {
            if (this.firstPreviusRentalItem) {
              this.firstPreviusRentalItem = false;
              return this.getRoundedOffValue(
                this.previousInvoiceRentItems
                  .map((rentItem) => rentItem.amount)
                  .reduce((sum, rentItem) => sum + rentItem, 0)
              );
            } else {
              return "-";
            }
          }
          case "Printing":
          case "Mounting": {
            if (this.firstPreviousPrintingMountingItem) {
              this.firstPreviousPrintingMountingItem = false;
              return this.getRoundedOffValue(
                this.previousInvoicePrintingItems
                  .map((printItem) => printItem.amount)
                  .reduce((sum, printItem) => sum + printItem, 0) +
                  this.previousInvoiceMountingItems
                    .map((mountItem) => mountItem.amount)
                    .reduce((sum, mountItem) => sum + mountItem, 0)
              );
            } else {
              return "-";
            }
          }
        }
        break;
      }
      case "Full Detail":
      case "Full Detail(Compress)": {
        const previousRentItems = this.items.filter(
          (item) => item.id === row.id
        );
        if (previousRentItems && previousRentItems.length) {
          return previousRentItems[0].amount;
        } else {
          return "-";
        }
      }
      default: {
        const previousRentItems = this.items.filter(
          (item) => item.id === row.id
        );
        if (previousRentItems && previousRentItems.length) {
          return previousRentItems[0].amount;
        } else {
          return "-";
        }
      }
    }
  }

  checkInvoiceFormat() {
    switch (
      this.isInvFormatFullDetail
        ? InvoiceFormatEnum[this._invoiceFormat]
        : InvoiceFormatEnum[this.invoice.invoiceFormat]
    ) {
      case "Full Detail":
      case "Full Detail(Compress)":
      case "Less Detail":
      case "Less Detail(Compress)":
      case "Package Rate inclusive of Printing/Mounting":
      case "Package Rate with separate Printing/Mounting": {
        return false;
      }

      case "As Per Ro inclusive of Printing/Mounting":
      case "As Per Ro with separate Printing/Mounting":
      case "As Per Annexure": {
        return true;
      }

      default: {
        return false;
      }
    }
  }

  /**
   * @description get rent hsn code
   * @author Raveena Nathani
   * @date 2020-08-05
   * @returns
   * @memberof InvoiceSummaryGridComponent
   */
  getRentHSNCode() {
    if (
      this.configurations.length > 0 &&
      +this.configurations.find((property) => property.key === "RENT_HSN_CODE")
        .value > 0
    ) {
      return +this.configurations.find(
        (property) => property.key === "RENT_HSN_CODE"
      ).value;
    } else {
      return 0;
    }
  }

  /**
   * @description get print hsn code
   * @author Raveena Nathani
   * @date 2020-08-05
   * @returns
   * @memberof InvoiceSummaryGridComponent
   */
  getPrintHSNCode() {
    if (
      this.configurations.length > 0 &&
      +this.configurations.find((property) => property.key === "PRINT_HSN_CODE")
        .value > 0
    ) {
      return +this.configurations.find(
        (property) => property.key === "PRINT_HSN_CODE"
      ).value;
    } else {
      return 0;
    }
  }

  /**
   * @description get mount hsn code
   * @author Raveena Nathani
   * @date 2020-08-05
   * @returns
   * @memberof InvoiceSummaryGridComponent
   */
  getMountHSNCode() {
    if (
      this.configurations.length > 0 &&
      +this.configurations.find((property) => property.key === "MOUNT_HSN_CODE")
        .value > 0
    ) {
      return +this.configurations.find(
        (property) => property.key === "MOUNT_HSN_CODE"
      ).value;
    } else {
      return 0;
    }
  }

  /**
   * @description get invoice format
   * @author Raveena Nathani
   * @date 2020-08-05
   * @returns
   * @memberof InvoiceSummaryGridComponent
   */
  getInvoiceFormat() {
    return InvoiceFormatEnum[this.invoiceFormat] ===
      InvoiceFormatEnum["WITH_ATTACHED_ANNEXURE"]
      ? true
      : false;
  }

  /**
   * @description check whether the first item is rent or not
   * @author Raveena Nathani
   * @date 2020-08-05
   * @returns
   * @memberof InvoiceSummaryGridComponent
   */
  checkForFirstItem() {
    if (this.items.length > 0 && this.items[0].type === "ANNEXURE") {
      return true;
    }
    return false;
  }

  /**
   * @description setting items after rows rearrangement
   * @author Raveena Nathani
   * @date 2020-08-06
   * @memberof InvoiceSummaryGridComponent
   */
  setItemsAfterRowRearrangement() {
    switch (
      this.isInvFormatFullDetail
        ? InvoiceFormatEnum[this._invoiceFormat]
        : InvoiceFormatEnum[this.invoice.invoiceFormat]
    ) {
      case "Full Detail":
      case "Full Detail(Compress)":
      case "Less Detail(Compress)":
      case "Less Detail":
      case "Package Rate inclusive of Printing/Mounting":
      case "Package Rate with separate Printing/Mounting": {
        this.items = [
          ...this.setUpdatedRentItems(),
          ...this.setUpdatedPrintItems(),
          ...this.setUpdatedMountItems(),
        ];
        this.emitRowsToRearrange();
        break;
      }
    }
  }

  /**
   * @description setting rent items after rows rearrangement
   * @author Raveena Nathani
   * @date 2020-08-06
   * @returns {CombinedItem[]}
   * @memberof InvoiceSummaryGridComponent
   */
  setUpdatedRentItems(): CombinedItem[] {
    const updatedRentItems = [];
    const rentItems = this.items.filter((item) => item.type === "Rent");
    const rowsOrder =
      this.invoiceRowsOrder.length > 0
        ? this.invoiceRowsOrder
        : this._invoice.rowsOrder;
    rowsOrder.forEach((id) => {
      const index = rentItems.findIndex((rentItem) => rentItem.id === id);
      const item = rentItems.find((rentItem) => rentItem.id === id);
      item ? updatedRentItems.push(item) : null;

      if (
        this._showChildren &&
        item &&
        item.childInventories &&
        item.childInventories.length > 0
      ) {
        for (let i = index + 1; i < rentItems.length; i++) {
          if (!rentItems[i].amount) {
            updatedRentItems.push(rentItems[i]);
          } else {
            break;
          }
        }
      }
    });

    const notAddedItems = rentItems.filter(
      (item) => updatedRentItems.indexOf(item) < 0
    );
    if (notAddedItems.length > 0) {
      notAddedItems.forEach((item) => {
        item && item.amount > 0 ? updatedRentItems.push(item) : null;
        const index = notAddedItems.findIndex(
          (rentItem) => rentItem.id === item.id
        );
        if (
          this._showChildren &&
          item &&
          item.childInventories &&
          item.childInventories.length > 0
        ) {
          for (let i = index + 1; i < notAddedItems.length; i++) {
            if (!notAddedItems[i].amount) {
              updatedRentItems.push(notAddedItems[i]);
            } else {
              break;
            }
          }
        }
      });
    }
    const rentHSNCode = this.getRentHSNCode();
    if (updatedRentItems.length > 0) {
      updatedRentItems.forEach((item, index) => {
        index === 0 ? (item.hsnCode = rentHSNCode) : (item.hsnCode = null);
      });
    }
    return updatedRentItems;
  }

  /**
   * @description setting printing items after rows rearrangement
   * @author Raveena Nathani
   * @date 2020-08-06
   * @returns
   * @memberof InvoiceSummaryGridComponent
   */
  setUpdatedPrintItems() {
    const updatedPrintingItems = [];
    const printingItems = this.items.filter((item) => item.type === "Printing");
    const rowsOrder =
      this.invoiceRowsOrder.length > 0
        ? this.invoiceRowsOrder
        : this._invoice.rowsOrder;
    rowsOrder.forEach((id) => {
      const index = printingItems.findIndex(
        (printingItem) => printingItem.id === id
      );
      const item = printingItems.find((printingItem) => printingItem.id === id);
      item ? updatedPrintingItems.push(item) : null;

      if (
        this._showChildren &&
        item &&
        item.childInventories &&
        item.childInventories.length > 0
      ) {
        for (let i = index + 1; i < printingItems.length; i++) {
          if (!printingItems[i].amount) {
            updatedPrintingItems.push(printingItems[i]);
          } else {
            break;
          }
        }
      }
    });

    const notAddedItems = printingItems.filter(
      (item) => updatedPrintingItems.indexOf(item) < 0
    );
    if (notAddedItems.length > 0) {
      notAddedItems.forEach((item) => {
        item && item.amount > 0 ? updatedPrintingItems.push(item) : null;
        const index = notAddedItems.findIndex(
          (printingItem) => printingItem.id === item.id
        );
        if (
          this._showChildren &&
          item &&
          item.childInventories &&
          item.childInventories.length > 0
        ) {
          for (let i = index + 1; i < notAddedItems.length; i++) {
            if (!notAddedItems[i].amount) {
              updatedPrintingItems.push(notAddedItems[i]);
            } else {
              break;
            }
          }
        }
      });
    }
    const printHSNCode = this.getPrintHSNCode();
    if (updatedPrintingItems.length > 0) {
      updatedPrintingItems.forEach((item, index) => {
        index === 0 ? (item.hsnCode = printHSNCode) : (item.hsnCode = null);
      });
    }
    return updatedPrintingItems;
  }

  /**
   * @description setting mounting items after rows rearrangement
   * @author Raveena Nathani
   * @date 2020-08-06
   * @returns
   * @memberof InvoiceSummaryGridComponent
   */
  setUpdatedMountItems() {
    const updatedMountingItems = [];
    const mountingItems = this.items.filter((item) => item.type === "Mounting");
    const rowsOrder =
      this.invoiceRowsOrder.length > 0
        ? this.invoiceRowsOrder
        : this._invoice.rowsOrder;
    // const rowsOrder = this.invoiceRowsOrder ? this.invoiceRowsOrder : this._invoice.rowsOrder;
    rowsOrder.forEach((id) => {
      const index = mountingItems.findIndex(
        (mountingItem) => mountingItem.id === id
      );

      const item = mountingItems.find((mountingItem) => mountingItem.id === id);
      item ? updatedMountingItems.push(item) : null;

      if (
        this._showChildren &&
        item &&
        item.childInventories &&
        item.childInventories.length > 0
      ) {
        for (let i = index + 1; i < mountingItems.length; i++) {
          if (!mountingItems[i].amount) {
            updatedMountingItems.push(mountingItems[i]);
          } else {
            break;
          }
        }
      }
    });

    const notAddedItems = mountingItems.filter(
      (item) => updatedMountingItems.indexOf(item) < 0
    );
    if (notAddedItems.length > 0) {
      notAddedItems.forEach((item) => {
        item && item.amount > 0 ? updatedMountingItems.push(item) : null;
        const index = notAddedItems.findIndex(
          (printingItem) => printingItem.id === item.id
        );
        if (
          this._showChildren &&
          item &&
          item.childInventories &&
          item.childInventories.length > 0
        ) {
          for (let i = index + 1; i < notAddedItems.length; i++) {
            if (!notAddedItems[i].amount) {
              updatedMountingItems.push(notAddedItems[i]);
            } else {
              break;
            }
          }
        }
      });
    }

    const mountHSNCode = this.getMountHSNCode();
    if (updatedMountingItems.length > 0) {
      updatedMountingItems.forEach((item, index) => {
        index === 0 ? (item.hsnCode = mountHSNCode) : (item.hsnCode = null);
      });
    }
    return updatedMountingItems;
  }

  /**
   * @description sorting items in group
   * @author Raveena Nathani
   * @date 2020-08-06
   * @memberof InvoiceSummaryGridComponent
   */
  sortingItemsBasedOnItsItemType() {
    const rentItems = this.sortRentItemInGroup();
    const printItems = this.sortPrintingItemsInGroup();
    const mountItems = this.sortMountingItemsInGroup();
    this.items = [];
    this.items = [...rentItems, ...printItems, ...mountItems];
  }

  sortRentItemInGroup() {
    const rentItems = this.items.filter(
      (item) => item.type === "Rent" && item.amount
    );
    const rentHSNCode = this.getRentHSNCode();
    const invoiceRentItems = [];
    rentItems.forEach((rentItem, index) => {
      index === 0
        ? (rentItem.hsnCode = rentHSNCode)
        : (rentItem.hsnCode = null);
      invoiceRentItems.push(rentItem);
      if (this._showChildren && rentItem.childInventories.length > 0) {
        rentItem.childInventories.forEach((child) => {
          invoiceRentItems.push(new CombinedItem().setRentItem(child, "Rent"));
        });
      }
    });
    return invoiceRentItems;
  }

  sortPrintingItemsInGroup() {
    const invoicePrintingItems = [];
    const printingItems = this.items.filter(
      (item) => item.type === "Printing" && item.amount
    );
    const printHSNCode = this.getPrintHSNCode();
    printingItems.forEach((printItem, index) => {
      index === 0
        ? (printItem.hsnCode = printHSNCode)
        : (printItem.hsnCode = null);
      invoicePrintingItems.push(printItem);
      if (printItem.childInventories.length && this._showChildren) {
        printItem.childInventories.forEach((child) => {
          invoicePrintingItems.push(
            new CombinedItem().setAdditionalItem(
              child,
              "Printing",
              printItem.lightType,
              printItem.printingPrice,
              null,
              true
            )
          );
        });
      }
    });

    return invoicePrintingItems;
  }

  sortMountingItemsInGroup() {
    const invoiceMountingItems = [];
    const mountHSNCode = this.getMountHSNCode();
    const mountingItems = this.items.filter(
      (item) => item.type === "Mounting" && item.amount
    );
    mountingItems.forEach((mountItem, index) => {
      index === 0
        ? (mountItem.hsnCode = mountHSNCode)
        : (mountItem.hsnCode = null);
      invoiceMountingItems.push(mountItem);
      if (mountItem.childInventories.length && this._showChildren) {
        mountItem.childInventories.forEach((child) => {
          invoiceMountingItems.push(
            new CombinedItem().setAdditionalItem(
              child,
              "Mounting",
              mountItem.lightType,
              null,
              mountItem.mountingPrice
            )
          );
        });
      }
    });

    return invoiceMountingItems;
  }

  setMonthlyRateHeader() {
    return this.auth.setMonthlyRateLabel();
  }
}
