import { Component, OnInit, ViewEncapsulation, ViewChild } from '@angular/core';
import { GridActionButtonConfig } from '../components/sib-forms/grid/grid-action-button.config';
import { GridActionButtonTypes, GridColumn, EditableGridFieldTypes, GridConfig, SelectionMode } from '../components/sib-forms/grid/grid.config';
import { Customer } from '../modals/KYC/customer';
import { FormGroup, FormControl } from '@angular/forms';
import * as utils from '../helpers/utils';
import { CampaignService } from '../services/shared/campaign.service';
import { Campaign } from '../modals/campaigns/campaign';
import { VendorService } from '../services/shared/vendor.service';
import { PublicPageService } from '../services/shared/publicpage.service';
import { MountingMaster } from '../modals/mounting/mounting-master';
import { MonitoringMaster } from '../modals/monitoring/monitoring-master';
import { UnmountingMaster } from '../modals/unmounting/unmounting-master';
import { CampaignItem } from '../modals/campaigns/campaignItem';
import { NotificatoinsService } from '../services/notifications/notifications.service';
import { PaginationData } from '../modals/paginationdata';
import { TotalsitesComponent } from './totalsites/totalsites.component';
import { MountedsitesComponent } from './mountedsites/mountedsites.component';
import { MonitoringsitesComponent } from './monitoringsites/monitoringsites.component';
import { UnmountingsitesComponent } from './unmountingsites/unmountingsites.component';
import { LoaderSubjects } from '../modals/loader-subjects/loader-subjects';
import { PPTLoader } from '../modals/loader-subjects/pptloader';
import { MenuItem, SelectItem } from 'primeng/primeng';
import { MenuConstants } from '../shared/constants/menu-constants';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { ImageTypeEnum } from '../shared/constants/image-type-enum';
import { ImageDataDisplay } from '../modals/image/image-data';
import { PhotoDialogComponent } from '../shared/components/photo-dialog/photo-dialog.component';
import { PhotoLoader } from '../modals/loader-subjects/photo-loader';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { MapMarker } from '../modals/map/MapMarker';
import { MapMetadata } from '../modals/map/map-metadata';
import { HoardingLightEnum } from '../shared/constants/hoarding-light-enum';
import { DateUtil } from '../helpers/date.util';
import { SIBConstants } from '../shared/constants/SIBConstant';
import { HoardingMapService } from '../services/shared/hoarding-map.service';
import * as fileUtils from '../helpers/file.util'
import * as _ from "lodash";
import { AppUrls } from '../services/urls';
import { Plan } from '../modals/plans/plan';
import { PlanPublicPageService } from '../services/shared/plan-public-page.service';
import { PlanItem } from '../modals/plans/planItem';
import { PlanService } from '../services/shared/plan.service';
import { PptFormatEnum } from '../modals/ppt/ppt-format-enum';

@Component({
  selector: 'sib-public-page',
  templateUrl: './public-page.component.html',
  styleUrls: ['./public-page.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PublicPageComponent implements OnInit {

  @ViewChild(TotalsitesComponent)
  campaignSitesComponent: TotalsitesComponent;

  @ViewChild(MountedsitesComponent)
  campaignMountingComponent: MountedsitesComponent;

  @ViewChild(MonitoringsitesComponent)
  campaignMonitoringComponent: MonitoringsitesComponent;

  @ViewChild(UnmountingsitesComponent)
  CampaignUnmountingComponent: UnmountingsitesComponent;

  @ViewChild(PhotoDialogComponent)
  photoDialogComponent: PhotoDialogComponent;

  currentType:string = "CAMPAIGN";

  x_tenant: string;
  campaign: Campaign = new Campaign();
  plan:Plan = new Plan();
  logoUrl: string;
  campaignItems: CampaignItem[] = [];
  planItems:PlanItem[]=[];
  campaignMountingItems: MountingMaster[] = [];
  campaignMonitoringItems: MonitoringMaster[] = [];
  campaignUnmountingItems: UnmountingMaster[] = [];
  isFileDownloading = false;
  progressValue = 0;
  searchEvent: PaginationData = {
    filters: {},
    globalFilter: '',
    // sortField: '',
    // sortOrder: 1,
    multiSortMetas: []
  };
  subModule: string;
  tabIndex = 0;
  totalGridItems: number;
  xlsLoader: PPTLoader = new PPTLoader();
  pptLoader: PPTLoader = new PPTLoader();
  xlsloader: PPTLoader = new PPTLoader();
  pptloader: PPTLoader = new PPTLoader();
  photoLoader: PhotoLoader = new PhotoLoader();
  exportItem: MenuItem[]; // export menu
  searchTerm: string;

  displayNoImageDialog = false; // displayed when there is no image in the campaign items
  displaySlideshowText: string;
  selectedImageType: ImageTypeEnum[];
  selectedImgType: boolean = false;
  displayPPTdialog: boolean = false;
  exportItemEvent: any;
  imageTypeOptions: SelectItem[];
  subject = new Subject<any>();
  isLoading: boolean = false;
  mapMarker: MapMarker = new MapMarker();
  overlays: any[] = [];
  options: any;
  showMap: boolean = false;
  mapMetadata: MapMetadata = new MapMetadata();
  displayName: string;
  billingName: string;
  from: string;
  to: string;
  campaignId: string;
  planId:string;
  resetXlsLoader: PPTLoader = new PPTLoader();
  resetPptloader: PPTLoader = new PPTLoader();
  // downloadFileLoader: boolean = false;
  downloadFileText: string = '';
  count: number = 0;

  mountingImagesData: any[] = [];
  unmountingImagesData: any[] = [];
  monitoringImagesData: any[] = [];
  imageData: any = [];

  constructor(
    private campaignService: CampaignService,
    private planService:PlanService,
    private vendorService: VendorService,
    private publicPageService: PublicPageService,
    private notificationService: NotificatoinsService,
    private loaderSubjects: LoaderSubjects,
    private router: Router,
    private hoardingMapService: HoardingMapService,
    private route: ActivatedRoute,
    private planPublicPageService:PlanPublicPageService
  ) { }
  ngOnInit() {

    this.route.paramMap.subscribe((params: Params) => {
      if (params) {
        this.x_tenant = params.get('x_tenant');
        if(params.get('campaignId')){
          this.campaignId = params.get('campaignId');
          this.currentType = "CAMPAIGN";
        }
        else if(params.get('planId')){
          this.planId = params.get('planId');
          this.currentType="PLAN";
        }

        this.getClientLogo();
      }
    })

    this.setExportMenu();
    this.setImageTypeOptions();
  }

  getCommaSeparatedFields(){
    if(this.currentType=='CAMPAIGN'){
      return "HID, City, Location"
    }
    else{
      return "HID, District, City, Location"
    }
  }

  /**
   * for global filtering
   *
   * @memberof PublicPageComponent
   */
  searchInGrid() {
    if(this.currentType=='CAMPAIGN'){
    this.campaignService.$search.next(this.searchTerm);
    }
    else if(this.currentType=='PLAN'){
      this.planService.$search.next(this.searchTerm);
    }
  }






  /**
   * @description gets client logo
   * @author Raveena Nathani
   * @date 2020-04-22
   * @memberof PublicPageComponent
   */
  getClientLogo() {
    this.vendorService.get({ x_tenant: this.x_tenant }, AppUrls.PUBLIC + AppUrls.LOGO_DETAIL).subscribe((response) => {
      if (response) {
        this.logoUrl = response['data'].logoDetail.url;
        if(this.currentType=='CAMPAIGN'){
        this.getCampaign();
        }
        else if(this.currentType=='PLAN'){
          this.getPlan();
        }
      }
    })
  }

  getPlan(){
    this.planPublicPageService.get(null,"/public/byPlan/"+this.planId + '?x_tenant='+this.x_tenant).subscribe((response)=>{
      this.plan = response['plan'];
      this.planItems = response['items'];
      this.displayName = this.plan.displayName + ',';
      this.billingName = this.plan.customer.billingName + ',';
      this.from = SIBConstants.FROM + ': ';
      this.to = SIBConstants.TO + ': ';
    })
  }

  /**
   * gets campaign details
   *
   * @memberof PublicPageComponent
   */
  getCampaign() {
    this.campaignService.get(null, '/public/view/' + this.campaignId + '?x_tenant=' + this.x_tenant).subscribe((response) => {
      this.campaign = response['data'];
      this.displayName = this.campaign.displayName + ',';
      this.billingName = this.campaign.customer.billingName + ',';
      this.from = SIBConstants.FROM + ': ';
      this.to = SIBConstants.TO + ': ';
      this.getCampaignItems();
    });
  }


  /**
   * gets campaign items
   *
   * @memberof PublicPageComponent
   */
  getCampaignItems() {
    this.publicPageService.get(null, '/public/byCampaign/' + this.campaignId + '?x_tenant=' + this.x_tenant).subscribe((response) => {
      this.campaignItems = response['data']['campaignItems']
      this.campaignMountingItems = response['data']['mountingItems'];
      this.campaignMonitoringItems = response['data']['monitoringItems'];
      this.campaignUnmountingItems = response['data']['unmountingItems'];
      this.isLoading = false;

    })
  }


  /**
   * sets export menu for ppt xslx and photos
   *
   * @memberof PublicPageComponent
   */
  setExportMenu() {
    if(this.currentType=='CAMPAIGN'){
    this.exportItem = [
      {
        label: MenuConstants.DOWNLOAD_PPT_L,
        icon: MenuConstants.DOWNLOAD_PPT_I,
        command: (event) => {
          if (!this.isFileDownloading) {
            this.showPPTDialog(event);
          } else {
            this.notificationService.info('Downloading is in progress', 'Download');
          }
        },
        visible: this.tabIndex !== 0 ? true : false
      },
      {
        label: MenuConstants.DOWNLOAD_XLSX_L,
        icon: MenuConstants.DOWNLOAD_XLSX_I,
        command: (event) => {
          this.exportToXLSX();
        },
      },
      {
        label: MenuConstants.DOWNLOAD_PHTOTS_L,
        icon: MenuConstants.DOWNLOAD_PHTOTS_I,
        command: (evnet) => {
          this.fetchImageUrls();
        },

      },

    ];
  }
  else if(this.currentType=='PLAN'){
    this.exportItem = [
      {
        label: MenuConstants.DOWNLOAD_PPT_L,
        icon: MenuConstants.DOWNLOAD_PPT_I,
        items:[
          {
            label: MenuConstants.DOWNLOAD_FULL_DETAIL,
            icon: MenuConstants.DOWNLOAD_PPT_I,
             command: (event) => {
                    if (!this.isFileDownloading) {
                      this.downloadPPT("FULL_DETAIL");
                    } else {
                      this.notificationService.info('Downloading is in progress', 'Download');
                    }
                  },
          },
          {
            label: MenuConstants.DOWNLOAD_LESS_DETAIL,
            icon: MenuConstants.DOWNLOAD_PPT_I,
             command: (event) => {
                    if (!this.isFileDownloading) {
                      this.downloadPPT("LESS_DETAIL");
                    } else {
                      this.notificationService.info('Downloading is in progress', 'Download');
                    }
                  },
          },
        ],
        
        visible: true
      },
      {
        label: MenuConstants.DOWNLOAD_XLSX_L,
        icon: MenuConstants.DOWNLOAD_XLSX_I,
        command: (event) => {
          this.exportToXLSX();
        },
      },
      {
        label: MenuConstants.DOWNLOAD_PHTOTS_L,
        icon: MenuConstants.DOWNLOAD_PHTOTS_I,
        command: (evnet) => {
          this.fetchImageUrls();
        },
      },

    ];
  }
  }


  /**
   * handles tab change 
   *
   * @param {*} event
   * @memberof PublicPageComponent
   */
  onTabChange(event) {
    this.tabIndex = event.index;
    this.setExportMenu();
  }


  /**
   * sets image type options
   *
   * @memberof PublicPageComponent
   */
  setImageTypeOptions() { // set options for imageType (used when downloading ppt )
    this.imageTypeOptions = utils.createDropdown(ImageTypeEnum, false);
  }


  /**
   * opens dialog to select image type
   *
   * @param {*} event
   * @memberof PublicPageComponent
   */
  showPPTDialog(event) {
    this.selectedImageType = [];
    this.selectedImgType = false;
    this.displayPPTdialog = true;
  }



  /**
   * checks whether any option from multiselect is selected or not
   *
   * @memberof PublicPageComponent
   */
  onFilterChange() {
    if (this.selectedImageType && this.selectedImageType.length > 0) {
      this.selectedImgType = true;
    } else {
      this.selectedImgType = false;
    }
  }


  /**
   * downloads the ppt
   *
   * @memberof PublicPageComponent
   */
  downloadPPT(pptType?) {
    this.downLoadFile('PPT',pptType);
    this.hidePPTDialog();
  }



  /**
   * hides PPT dialog
   *
   * @memberof PublicPageComponent
   */
  hidePPTDialog() {
    this.displayPPTdialog = false;
    this.selectedImageType = [];
  }


  /**
   * sets submodule based on tab selection for exporting xlsx
   *
   * @memberof PublicPageComponent
   */
  exportToXLSX() {
    switch (this.tabIndex) {
      case 0: {
        if(this.currentType=='CAMPAIGN'){
        this.subModule = "campaignItems";
        this.totalGridItems = this.campaignItems.length;
        }
        else if(this.currentType=='PLAN'){
          this.subModule == 'planItems';
          this.totalGridItems = this.planItems.length;
        }
        break;
      }
      case 1: {
        this.subModule = "mountingItems";
        this.totalGridItems = this.campaignMountingItems.length;
        break;
      }
      case 2: {
        this.subModule = "monitoringItems";
        this.totalGridItems = this.campaignMonitoringItems.length;
        break;
      }
      case 3: {
        this.subModule = "unmountingItems";
        this.totalGridItems = this.campaignUnmountingItems.length;
        break;
      }
      default: {
        this.subModule = "campaignItems";
        this.totalGridItems = this.campaignItems.length;
      }
    }
    this.downLoadFile("XLS");
  }



  /**
   * downloads file based on file type XLSX or PPT
   *
   * @param {*} fileType
   * @memberof PublicPageComponent
   */
  downLoadFile(fileType,pptType?) {
    this.isFileDownloading = true;

    const interval = setInterval(() => {
      this.progressValue = this.progressValue + Math.floor(Math.random() * 10) + 1;
      if (this.progressValue >= 100) {
        this.progressValue = 100;
        clearInterval(interval);
      }
    }, 290);

    if (fileType === 'XLS' && this.totalGridItems) {
      this.createLoaderObject(fileType, this.xlsLoader)
      this.downloadCampaignTrackerXls();

    } else if (fileType === 'XLS' && !this.totalGridItems) {
      this.notificationService.info("No Items Present", "Cannot Download XLSX");
    } else if (fileType === 'PPT') {
      this.createLoaderObject(fileType, this.pptLoader, pptType);
      this.downloadCampaignTrackerPpt();

    }

    this.isFileDownloading = false;
  }


  /**
   * creates the loader object
   *
   * @param {*} fileType
   * @param {*} object
   * @memberof PublicPageComponent
   */
  createLoaderObject(fileType, object,pptType?) {
    let itemIdsAndSubModule: any = {};
    object.isLoading = true;
    if (fileType === 'XLS') {
      this.downloadFileText = 'Downloading XLS...';
      object.text = 'Downloading XLS...';
      object.data = this.currentType=='CAMPAIGN' ? this.campaign : this.plan;
      object.fileType = fileType;
      object.searchEvent = this.searchEvent;
      object.customIds = this.getCustomIdsBasedOnTabIndex();
      object.subModule = this.subModule;
    } else if (fileType === 'PPT') {
      this.downloadFileText = 'Downloading PPT...';
      object.fileType = fileType;
      object.text = 'Downloading PPT...';
      object.data = this.currentType=='CAMPAIGN' ? this.campaign : this.plan;
      itemIdsAndSubModule = this.setItemIdsAndSubModule();
      if(this.currentType=='CAMPAIGN'){
      object.pptData = { 'imageTypes': this.selectedImageType, 'itemIds': itemIdsAndSubModule.itemIds, 'subModule': itemIdsAndSubModule.subModule, tabIndex: this.tabIndex };
      }
      else if(this.currentType=='PLAN'){
        object.pptData = {'customIds':itemIdsAndSubModule.itemIds,'format': pptType? pptType : "LESS_DETAIL"}
      }

    }
  }


  /**
   * sets items ids and sub module
   *
   * @returns
   * @memberof PublicPageComponent
   */
  setItemIdsAndSubModule() {
    let subModule = "";
    let itemIds: any[] = [];
    switch (this.tabIndex) {
      case 0:{
        if(this.currentType=='PLAN'){
          itemIds = this.campaignSitesComponent.getIds();
          subModule = "PlanItems";
          this.displaySlideshowText = "Plan Items";
        }
        break;
      }
      case 1: {
        itemIds = this.getMountingItemsIds()
        subModule = "mountingItems";
        this.displaySlideshowText = "Mounting Items";
        break;
      }
      case 2: {
        itemIds = this.getMonitoringItemsIds();
        subModule = "monitoringItems";
        this.displaySlideshowText = "Monitoring Items";
        break;
      }
      case 3: {
        itemIds = this.getUnmountingItemsIds();
        subModule = "unmountingItems";
        this.displaySlideshowText = "Unmounting Items";
        break;
      }
    }
    return { 'itemIds': itemIds, 'subModule': subModule };
  }


  /**
   * gets mounting item ids
   *
   * @returns
   * @memberof PublicPageComponent
   */
  getMountingItemsIds() {
    let mountingItemIds: any[] = [];
    this.campaignMountingComponent.getMountingItems().forEach((mountingItem) => {
      mountingItem.mountingItems ? mountingItemIds.push(mountingItem.mountingItems.id) : "";
      // console.log(mountingItem.customId)
    });
    return mountingItemIds;
  }

  /**
   * gets monitoring item ids
   *
   * @returns
   * @memberof PublicPageComponent
   */
  getMonitoringItemsIds() {
    let monitoringItemIds: any[] = [];

    this.campaignMonitoringComponent.getMonitoringItems().forEach((monitoringItem) => {
      monitoringItem.monitoringItems ? monitoringItemIds.push(monitoringItem.monitoringItems.id) : "";
      // console.log(monitoringItem.customId)
    });
    return monitoringItemIds;
  }

  /**
   * gets unmounting item ids
   *
   * @returns
   * @memberof PublicPageComponent
   */
  getUnmountingItemsIds() {
    let unmountingItemIds: any[] = [];
    this.CampaignUnmountingComponent.getUnmountingItems().forEach((unmountingItem) => {
      unmountingItem.unmountingItems ? unmountingItemIds.push(unmountingItem.unmountingItems.id) : "";
      // console.log(unmountingItem.customId)
    });
    return unmountingItemIds;
  }

  /**
   * get custom ids based on tab index
   *
   * @returns
   * @memberof PublicPageComponent
   */
  getCustomIdsBasedOnTabIndex() {
    switch (this.tabIndex) {
      case 0: {
        return this.campaignSitesComponent.getIds();
      }
      case 1: {
        return this.campaignMountingComponent.getIds();
      }
      case 2: {
        return this.campaignMonitoringComponent.getIds();
      }

      case 3: {
        return this.CampaignUnmountingComponent.getIds();
      }
    }
  }



  /**
   * fetch image urls for export photos option
   *
   * @memberof PublicPageComponent
   */
  fetchImageUrls() {
    this.count = 0;
    switch (this.tabIndex) {
      case 0: {
        const imgs: { name: string, url: string | ArrayBuffer, description?: string }[] = [];
        const fileName =  this.currentType=='CAMPAIGN' ? this.campaign.displayName + '-sites' : this.plan.displayName + '-sites';
        this.campaignSitesComponent.getCampaignSites().forEach((item:any,index) => {
          if (item && this.currentType=='CAMPAIGN' && item.images) {
            item.images.forEach((image) => imgs.push({ name: image.name, url: image.url, description: this.getImageDescription(item, image) }));
          }
          else if(item && this.currentType=="PLAN" && item.inventory && item.inventory.images){
            item.inventory.images.forEach((image,innerIndex) => imgs.push({ name: image.name, url: image.url, description: this.getImageDescription(item, image,index+1,innerIndex+1) }));
          }
        });
        if (imgs && imgs.length) {
          this.setPhotLoaderProperties(fileName, imgs);
        }
        if (imgs.length === 0) {
          this.displaySlideshowText = this.currentType=='CAMPAIGN' ? "Campaign Items" : "Plan Items";
          this.displayNoImageDialog = true;
        }
        break;
      }
      case 1: {
        const imgs: { name: string, url: string | ArrayBuffer, description?: string }[] = [];
        const fileName = this.campaign.displayName + '-mounting';
        this.campaignMountingComponent.getMountingItems().forEach((item) => {
          if (item && item.mountingItems && item.mountingItems.images) {
            item.mountingItems.images.forEach((image) => imgs.push({ name: image.name, url: image.url, description: this.getImageDescription(item, image) }));
          }
        });
        if (imgs && imgs.length) {
          this.setPhotLoaderProperties(fileName, imgs);
        }
        if (imgs.length === 0) {
          this.displaySlideshowText = "Mounting Items";
          this.displayNoImageDialog = true;
        }
        break;
      }
      case 2: {
        const imgs: { name: string, url: string | ArrayBuffer, description?: string }[] = [];
        const fileName = this.campaign.displayName + '-monitoring';
        this.campaignMonitoringComponent.getMonitoringItems().forEach((item) => {
          if (item && item.monitoringItems && item.monitoringItems.images) {
            item.monitoringItems.images.forEach((image) => imgs.push({ name: image.name, url: image.url, description: this.getImageDescription(item, image) }));
          }
        });
        if (imgs && imgs.length) {
          this.setPhotLoaderProperties(fileName, imgs);
        }
        if (imgs.length === 0) {
          this.displaySlideshowText = "Monitoring Items";
          this.displayNoImageDialog = true;
        }
        break;
      }
      case 3: {
        const imgs: { name: string, url: string | ArrayBuffer, description?: string }[] = [];
        const fileName = this.campaign.displayName + '-unmounting';
        this.CampaignUnmountingComponent.getUnmountingItems().forEach((item) => {
          if (item && item.unmountingItems && item.unmountingItems.images) {
            item.unmountingItems.images.forEach((image) => imgs.push({ name: image.name, url: image.url, description: this.getImageDescription(item, image) }));
          }
        });
        if (imgs && imgs.length) {
          this.setPhotLoaderProperties(fileName, imgs);
        }
        if (imgs.length === 0) {
          this.displaySlideshowText = "Unmounting Items";
          this.displayNoImageDialog = true;
        }
        break;
      }
    }
  }


  /**
   * sets images description
   * 
   * @param {*} item
   * @param {*} image
   * @returns
   * @memberof PublicPageComponent
   */
  getImageDescription(item, image,index?,innerIndex?) {
    if(this.currentType=="CAMPAIGN"){
      const hid = item.customId ? item.customId : '';
      const city = item.city ? item.city : '';
      const location = item.location ? item.location : '';
      const size = item.size ? item.size : '';
      const imageType = image.imageType ? ImageTypeEnum[image.imageType].toLocaleLowerCase() : '';
      const extension = this.getImageExtension(image);
      this.count = this.count + 1;
      return (this.count !== 0 ? this.count + ')' + ' ' : '') + hid + (city && city !== '' && city !== null ? ' - ' + city : '') + (location && location !== '' && location !== null ? ', ' + location : '') + (size && size !== '' && size !== null ? ' - ' + size : '') + (imageType && imageType !== '' && imageType !== null ? ' - ' + imageType : '') + (extension && extension !== '' && extension !== null ? extension : '.jpeg');
    }
    else{
      const hid = item.inventory.customId ? item.inventory.customId : '';
      const city = item.inventory.city ? item.inventory.city : '';
      const location = item.inventory.location ? item.inventory.location : '';
      const size = item.inventory.size ? item.inventory.size : '';
      const imageType = image.imageType ? ImageTypeEnum[image.imageType].toLocaleLowerCase() : '';
      const extension = this.getImageExtension(image);
      this.count = this.count + 1;
      return (index ? innerIndex ? index+'.'+innerIndex + ')' + ' ' : index  + ')' + ' ' : '' ) + hid + (city && city !== '' && city !== null ? ' - ' + city : '') + (location && location !== '' && location !== null ? ', ' + location : '') + (size && size !== '' && size !== null ? ' - ' + size : '') + (imageType && imageType !== '' && imageType !== null ? ' - ' + imageType : '') + (extension && extension !== '' && extension !== null ? extension : '.jpeg');
      }
    
  }


  /**
   * set images extension
   *
   * @param {*} image
   * @returns
   * @memberof PublicPageComponent
   */
  getImageExtension(image) {
    const splitImage = image.name.split('.');
    return '.' + splitImage[splitImage.length - 1];
  }


  /**
   * sets photo loader
   *
   * @param {*} fileName
   * @param {*} images
   * @memberof PublicPageComponent
   */
  setPhotLoaderProperties(fileName, images) {
    this.downloadFileText = 'Downloading photos...';
    this.photoLoader.isLoading = true;
    // this.photoLoader.text = 'Downloading photos...';
    this.photoLoader.name = fileName;
    this.photoLoader.items = images;
    if (this.photoLoader.items && this.photoLoader.items.length > 0) {
      this.downloadImages(this.photoLoader)
    }
  }



  /** 
   * Downloads images
   *
   * @param {*} object
   * @memberof PublicPageComponent
   */
  downloadImages(object) {
    if (object.items.length > 0) {
      this.createFileBlobs(object.items, object.name);
    }

    // this.downloadFileText = '';
  }


  /**
   * creates file blobs
   *
   * @param {*} imgs
   * @param {*} fileName
   * @memberof PublicPageComponent
   */
  createFileBlobs(imgs, fileName) {
    const blobs: any[] = [];
    const events: any[] = [];
    imgs.forEach((img, ind) => {
      const xhr = new XMLHttpRequest();
      xhr.responseType = 'blob';
      xhr.onload = (event) => {
        blobs.push(xhr.response);
        events.push(event);
        if (imgs.length === blobs.length) {
          fileUtils.downloadZipFile(imgs, blobs, events, fileName);
          this.downloadFileText = '';
        }
      };
      xhr.open('GET', imgs[ind].url);
      xhr.send();
    });
  }



  /**
   * xlsx download for campaign tracker
   *
   * @memberof PublicPageComponent
   */
  downloadCampaignTrackerXls() {
    if (this.xlsLoader.data) {
      if(this.currentType=='CAMPAIGN'){
      this.campaignService.downloadCampaignTrackerFile(this.xlsLoader.data.id, this.xlsLoader.fileType, this.x_tenant, this.xlsLoader.subModule, this.xlsLoader.customIds)
        .subscribe((response) => {
          let blob;
          let url;
          let anchor;
          blob = new Blob([response], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
          url = URL.createObjectURL(blob);
          anchor = document.createElement("a");
          anchor.download = this.setFileName(this.xlsLoader.subModule, this.xlsLoader.fileType, this.xlsLoader.data.displayName);
          anchor.href = url;
          anchor.click();
          this.downloadFileText = '';

        },
          (error) => {
            this.downloadFileText = '';
            this.notificationService.error("XLSX Download Error", "Please refresh and retry"); //////
          })
        }

        else if(this.currentType == 'PLAN'){
          this.planService.downloadPlanTrackerFile(this.xlsLoader.data.id, this.xlsLoader.fileType, this.x_tenant, this.xlsLoader.subModule, this.xlsLoader.customIds)
        .subscribe((response) => {
          let blob;
          let url;
          let anchor;
          blob = new Blob([response], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
          url = URL.createObjectURL(blob);
          anchor = document.createElement("a");
          anchor.download = this.setFileName(this.xlsLoader.subModule, this.xlsLoader.fileType, this.xlsLoader.data.displayName);
          anchor.href = url;
          anchor.click();
          this.downloadFileText = '';

        },
          (error) => {
            this.downloadFileText = '';
            this.notificationService.error("XLSX Download Error", "Please refresh and retry"); //////
          })
        }
    }
  }



  /**
   * PPT download for tracker
   *
   * @memberof PublicPageComponent
   */
  downloadCampaignTrackerPpt() {
    if (this.pptLoader.data) {
      if(this.currentType=='CAMPAIGN'){
      this.campaignService.downloadCampaignTrackerFile(this.pptLoader.data.id, this.pptLoader.fileType, this.x_tenant, null, this.pptLoader.pptData)
        .subscribe((response: any) => {
          let blob;
          const anchor = document.createElement("a");
          blob = new Blob([response], { type: 'application/vnd.openxmlformats-officedocument.presentationml.presentation' });
          anchor.download = this.setFileName(this.pptLoader.pptData.subModule, this.pptLoader.fileType, this.pptLoader.data.displayName);
          const url = URL.createObjectURL(blob);
          anchor.href = url;
          anchor.click();
          this.pptLoader.isLoading = false;
          this.pptLoader.text = null;
          this.downloadFileText = '';
        },
          (error) => {
            this.pptLoader.isLoading = false;
            this.pptLoader.text = null;
            this.downloadFileText = '';
            if (this.pptLoader.pptData.subModule === 'mountingItems') {
              this.displaySlideshowText = "Mounting Items";

            } else if (this.pptLoader.pptData.subModule === 'monitoringItems') {
              this.displaySlideshowText = "Monitoring Items";

            } else if (this.pptLoader.pptData.subModule === 'unmountingItems') {
              this.displaySlideshowText = "Unmounting Items";

            }
            this.displayNoImageDialog = true;
            // if (error.status === 412 || (error.error && error.error.code === 412)) {
            //   this.displayNoImageDialog = true;
            // } else {
            //   this.notificationService.error("PPT Download Error", "Please refresh and retry"); ///////
            // }
          }
        )
      }

      else if(this.currentType=='PLAN'){
        this.planService.downloadPlanTrackerFile(this.pptLoader.data.id, this.pptLoader.fileType, this.x_tenant, null, this.pptLoader.pptData)
        .subscribe((response: any) => {
          let blob;
          const anchor = document.createElement("a");
          blob = new Blob([response], { type: 'application/vnd.openxmlformats-officedocument.presentationml.presentation' });
          anchor.download = this.setFileName(this.pptLoader.pptData.subModule, this.pptLoader.fileType, this.pptLoader.data.displayName);
          const url = URL.createObjectURL(blob);
          anchor.href = url;
          anchor.click();
          this.pptLoader.isLoading = false;
          this.pptLoader.text = null;
          this.downloadFileText = '';
        },
          (error) => {
            this.pptLoader.isLoading = false;
            this.pptLoader.text = null;
            this.downloadFileText = '';
            this.displaySlideshowText="Plan Items";
            this.displayNoImageDialog = true;
            // if (error.status === 412 || (error.error && error.error.code === 412)) {
            //   this.displayNoImageDialog = true;
            // } else {
            //   this.notificationService.error("PPT Download Error", "Please refresh and retry"); ///////
            // }
          }
        )
      }
    } else {
      this.displayNoImageDialog = true;
    }
  }



  /**
   * sets downloaded file name for xlsx and ppt
   *
   * @param {*} subModule
   * @param {*} fileType
   * @param {*} displayName
   * @returns
   * @memberof PublicPageComponent
   */
  setFileName(subModule, fileType, displayName) {
    let itemType = '';
    switch (subModule) {
      case 'campaignItems': {
        itemType = "Sites";
        break;
      }
      case 'mountingItems': {
        itemType = "Mount";
        break;
      }
      case 'monitoringItems': {
        itemType = "Monitored";
        break;
      }
      case 'unmountingItems': {
        itemType = "Unmount";
        break;
      }
    }

    return displayName + '-' + itemType + '-' + DateUtil.dategridFormatter(new Date()) + (fileType === 'XLS' ? '.xlsx' : ".pptx")
  }



  /**
   * set count for sites tab when items are filtered using global filter
   *
   * @param {*} event
   * @memberof PublicPageComponent
   */
  setCampaignItemsLength(event) {
    this.campaign.totalItems = event;
  }
  setPlanItemsLength(event){
    this.plan.totalItems = event;
  }



  /**
   * set count for mounting tab when items are filtered using global filter
   *
   * @param {*} event
   * @memberof PublicPageComponent
   */
  setCampaignMountingItems(event) {
    this.campaign.totalMountItems = event.length;
    this.campaign.totalMountedItems = 0;
    event.forEach((item) => {
      if (item.mountingItems.imageStatus !== 'PENDING_MOUNTING') {
        this.campaign.totalMountedItems += 1;
      }
    })
  }


  /**
   * set count for monitoring tab when items are filterd using global filter
   *
   * @param {*} event
   * @memberof PublicPageComponent
   */
  setCampaignMonitoringItems(event) {
    this.campaign.totalMonitoringItems = event.length;
    this.campaign.totalMonitoredItems = 0;
    event.forEach((item) => {
      if (item.monitoringItems.status !== 'PENDING_MONITORING') {
        this.campaign.totalMonitoredItems += 1;
      }
    })
  }


  /**
   * set count for unmounting tab when items are filtered using global filter
   *
   * @param {*} event
   * @memberof PublicPageComponent
   */
  setCampaignUnmountingItems(event) {
    this.campaign.totalUnmountingItems = event.length;
    this.campaign.totalUnmountedItems = 0;
    event.forEach((item) => {
      if (item.unmountingItems.imageStatus !== 'PENDING_UNMOUNTING') {
        this.campaign.totalUnmountedItems += 1;
      }
    })
  }


  /**
   *  view all images on basis of tabIndex
   * 
   * @memberof PublicPageComponent
   */
  viewImages() {

    var imageData = [];
    switch (this.tabIndex) {
      case 0: {
        imageData = this.campaignItems;
        break;
      }
      case 1: {
        imageData = this.campaignMountingItems;
        break;
      }
      case 2: {
        imageData = this.campaignMonitoringItems;
        break;
      }
      case 3: {
        imageData = this.campaignUnmountingItems;
        break;
      }

    }
    this.photoDialogComponent.imageData = this.getImageData(imageData);
    this.photoDialogComponent.showImages();
  }


  /**
   * gets the image data for all images
   *
   * @param {*} data
   * @returns
   * @memberof PublicPageComponent
   */
  getImageData(data) {
    const imageData = [];
    switch (this.tabIndex) {
      case 0: {

        data.forEach((item) => {
          if (item.images.length > 0) {
            item.images.forEach((image) => {
              imageData.push(new ImageDataDisplay().getImageData(item, image));
            })
          }
        })

        if (imageData.length === 0) {
          this.displayNoImageDialog = true;
          this.displaySlideshowText = "Campaign Items";
        }

        break;
      }
      case 1: {

        data.forEach((item) => {
          if (item.mountingItems.images.length > 0) {
            item.mountingItems.images.forEach((image) => {
              imageData.push(new ImageDataDisplay().getImageData(item, image));
            })
          }
        })

        if (imageData.length === 0) {
          this.displayNoImageDialog = true;
          this.displaySlideshowText = "Mounting Items";
        }

        break;
      }
      case 2: {

        data.forEach((item) => {
          if (item.monitoringItems.images.length > 0) {
            item.monitoringItems.images.forEach((image) => {
              imageData.push(new ImageDataDisplay().getImageData(item, image));
            })
          }
        })

        if (imageData.length === 0) {
          this.displayNoImageDialog = true;
          this.displaySlideshowText = "Monitoring Items";
        }

        break;
      }
      case 3: {

        data.forEach((item) => {
          if (item.unmountingItems.images.length > 0) {
            item.unmountingItems.images.forEach((image) => {
              imageData.push(new ImageDataDisplay().getImageData(item, image));
            })
          }
        })

        if (imageData.length === 0) {
          this.displayNoImageDialog = true;
          this.displaySlideshowText = "Unmounting Items";
        }

        break;
      }

    }

    return imageData;
  }



  /**
   * view row item images
   *
   * @param {*} rowData
   * @memberof PublicPageComponent
   */
  viewPhotos(rowData) {
    this.imageData = [];
    this.imageData = this.toImageData(rowData);
    this.imageData = this.getItemImageData(this.imageData);
    this.showPhotoDialog(this.imageData);
  }


  /**
   * gets image data
   *
   * @param {*} data
   * @returns
   * @memberof PublicPageComponent
   */
  getItemImageData(data) {
    const imageData = [];
    for (let i = 0; i < data.images.length; i++) {
      imageData.push(new ImageDataDisplay().getImageData(data, data.images[i]))
    }
    return imageData;
  }


  /**
   * converting to image data
   *
   * @param {*} data
   * @returns
   * @memberof PublicPageComponent
   */
  toImageData(data) {
    const imageData = {};
    imageData['size'] = data.size;
    imageData['location'] = data.location;
    imageData['city'] = data.city;
    imageData['images'] = this.tabIndex === 0 ? data.images : (this.tabIndex === 1 ? data.mountingItems.images : (this.tabIndex === 2 ? data.monitoringItems.images : data.unmountingItems.images));
    imageData['customId'] = data.customId;
    return imageData;
  }




  /**
   * show photo dialog
   *
   * @param {*} imageData
   * @memberof PublicPageComponent
   */
  showPhotoDialog(imageData) {
    this.photoDialogComponent.imageData = imageData;
    this.photoDialogComponent.showImages();
  }



  /**
   * resets the imageData of photo dialog component
   *
   * @param {*} $event
   * @memberof PublicPageComponent
   */
  changePhotoDisplay($event) {
    this.photoDialogComponent.imageData = [];
  }


}
